import { config } from '../../config';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, Observable, BehaviorSubject, tap } from 'rxjs';
import { ADEntries } from 'src/app/interfaces/ad-entries';
import { ADSummaryEntries } from './api-ADSummary';

@Injectable({
  providedIn: 'root',
})
export class AnalysisDefinitionSummaryEntriesService {
  private uri = config.apiBaseUri + 'analysisDefinitions/getADSummary';
  private tableDataSubject = new BehaviorSubject<any[]>([]);
  constructor(private http: HttpClient) {
    this.fetchData();
  }

  fetchData() {
    this.http.get<any[]>(this.uri).subscribe((data) => {
      const latestData = data.reduce((acc, curr) => {
        const existing = acc.find(
          (item: any) => item.analysisId === curr.analysisId,
        );
        if (!existing || existing.version < curr.version) {
          return acc
            .filter((item: any) => item.analysisId !== curr.analysisId)
            .concat(curr);
        }
        return acc;
      },
       []);

      const transformedData = latestData.map((item: any) => ({
        analysisName: item.analysisName,
        analysisId: item.analysisId,
        analysisStatus: item.analysisStatus,
        version: item.version,
        cropName: Array.isArray(item.cropName)
          ? item.cropName.join(', ')
          : 'N/A',
      }));
      this.tableDataSubject.next(latestData);
    });
  }
  getTableData(): Observable<any[]> {
    return this.tableDataSubject.asObservable();
  }
}
