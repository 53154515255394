<div *ngIf="this.jsonform != null; else loading">
  <ng-container>
    <header class="analysis-creation-header">AD Details _ {{ header }}</header>
  </ng-container>
  <div>
    <div class="summaryForm">
      <div class="summary-header">
        <ng-container>
          <header class="analysis-header">Summary</header>
          <button pButton type="button" (click)="editAD()">
            Edit AD Summary
          </button>
        </ng-container>
      </div>

      <form [formGroup]="summaryForm">
        <ng-container>
          <div class="form-row">
            <div class="form-group">
              <label>Name:</label>
              <input pInputText formControlName="adName" [attr.disabled]="true" />
            </div>
            <div class="form-group">
              <label>Selected Traits:</label>
              <label>{{ concatSelectedTraits }}</label>
            </div>
          </div>
          <div class="form-row">
            <div class="form-group">
              <label class="assay-mrg-rt">Assay Oligo Box:</label>
              <input pInputText type="text" formControlName="assayOligo" [attr.disabled]="!isEditOpen ? true : null" />
            </div>
            <div class="form-group">
              <label class="status-mrg-rt">AD Status:</label>
              <p-dropdown placeholder="Select Status" formControlName="adStatus" [options]="statusOptions" [ngModel]="statusOptions[0]"
                [disabled]="!isEditOpen"></p-dropdown>
            </div>
          </div>
          <div class="form-row">
            <div class="form-group">
              <label class="crtby-mrg-rt">Created By:</label>
              <input [attr.disabled]="true" pInputText formControlName="createdBy" />
            </div>

            <div class="form-group">
              <label class="crton-mrg-rt">Created On:</label>
              <input pInputText type="text" [value]="
                  summaryForm.get('createdOn')?.value | date : 'MM/dd/yyyy'
                " [attr.disabled]="true" />
            </div>
          </div>
        </ng-container>
      </form>
      <div class="button-container-save">
        <button *ngIf="isEditOpen" pButton class="validate-button" label="Save" (click)="summarySaveForm()"></button>
      </div>
    </div>

    <div class="Analysis-Definition-Entries-Container">
      <div class="analysis-definition-analysis-header">
        <ng-container>
          <div class="header-width">
            <header class="analysis-header">Analysis Definition Entries</header>
          </div>
          <div class="count-btn-width">
            <button class="count-button">{{ count }}</button>
          </div>
          <div>
            <button class="newADE-width" pButton type="button" (click)="AddNewADE()">
              + Add New ADE
            </button>
          </div>
        </ng-container>
      </div>
      <p-accordion [multiple]="true">
        <p-accordionTab *ngFor="let section of accordionSections; let i = index" [selected]="i === selectedAccordianIndex">
          <p-header style="width: 100%;">
            <div class="ADEHeaderStyle">
              <ng-container>
                <div class="header-width ade-header-width">
                  <button class="button-container" *ngIf="section.form.get('Crop')?.value" class="selected-value-button">
                    {{ section.form.get("Crop")?.value }}
                  </button>
                  <button class="button-container" *ngIf="section.form.get('AssayType')?.value" class="selected-value-button">
                    {{ section.form.get("AssayType")?.value }}
                  </button>
                  <button class="button-container" *ngIf="section.form.get('TissueType')?.value" class="selected-value-button">
                    {{ section.form.get("TissueType")?.value }}
                  </button>
                  <button class="button-container" *ngIf="section.form.get('TissuePreparation')?.value" class="selected-value-button">
                    {{ section.form.get("TissuePreparation")?.value }}
                  </button>
                  <button class="button-container" *ngIf="section.form.get('TissueSampling')?.value" class="selected-value-button">
                    {{ section.form.get("TissueSampling")?.value }}
                  </button>
                </div>
                <div class="ADE-header-align">
                  <button class="button-container" *ngIf="section.form.get('adeStatus')?.value" class="selected-value-button">
                    {{ section.form.get("adeStatus")?.value }}
                  </button>
                </div>
                <div>
                  <button pButton type="button" class="newManage-ADE"
                    (click)="showOptions = !showOptions; menu.toggle($event); getAdeOptions(i); $event.stopPropagation()">
                    Mange ADE
                  </button>
                  <p-menu #menu [model]="adeOptions" (click)="$event.stopPropagation()" [popup]="true">
                  </p-menu>
                </div>
              </ng-container>
            </div>
          </p-header>
          <div>
            <form [formGroup]="section.form" *ngIf="isADEFormVisible">
              <ng-container>
                <div class="form-row">
                  <div class="form-group">
                    <label>ADE Status:</label>
                    <p-dropdown formControlName="adeStatus" placeholder="Select Status" [options]="adeStatusOptions"
                      [disabled]="!isADEEdit[i]"></p-dropdown>
                  </div>
                  <div class="form-group">
                    <label>Last Modified By:</label>
                    <input pInputText type="text" [value]="section.form.get('lastModifiedBy')?.value" [attr.disabled]="true" />
                  </div>
                </div>
                <div class="form-row">
                  <div class="form-group">
                    <label>Assay Group:</label>
                    <p-multiSelect formControlName="assayGroup" placeholder="Select Assays" (onClick)="onAssayGroupChange(i)" [options]="assayGroups"
                      [disabled]="!isADEEdit[i]">
                    </p-multiSelect>
                  </div>
                  <div class="form-group">
                    <label class="ade-modDt-mrg-rt">Last Modified Date:</label>
                    <input pInputText type="text" [value]="
                        section.form.get('lastModifiedDate')?.value
                          | date : 'MM/dd/yyyy'
                      " [attr.disabled]="true" />
                  </div>
                </div>
                <div class="form-row">
                  <div class="form-group">
                    <label>Backbone:</label>
                    <p-dropdown formControlName="backbone" placeholder="Select Backbone" [options]="backboneOptions"
                      [disabled]="!isADEEdit[i]"></p-dropdown>
                  </div>
                  <div class="form-group">
                    <label>Control Template:</label>
                    <p-dropdown placeholder="Select Control Template" [options]="controlTemplateOptions" formControlName="ControlTemplate" [disabled]="
                        i < initialCount || (i >= initialCount && !isADEEdit[i])
                      "></p-dropdown>
                  </div>
                </div>
                <div class="form-row">
                  <div class="form-group">
                    <label>Lab:</label>
                    <p-dropdown placeholder="Select Lab" [options]="labOptions" formControlName="Lab" [disabled]="
                        i < initialCount || (i >= initialCount && !isADEEdit[i])
                      " (onChange)="onLabChange($event.value, i)"></p-dropdown>
                  </div>
                  <div class="form-group">
                    <label>Master Mix:</label>
                    <p-dropdown placeholder="Select Master Mix" [options]="masterMixOptions" formControlName="MasterMix"
                      [disabled]="!isADEEdit[i]"></p-dropdown>
                  </div>
                </div>
                <div class="form-row">
                  <div class="form-group">
                    <label>Crop:</label>
                    <p-dropdown formControlName="Crop" (onChange)="onCropChange($event.value,i)" placeholder="Select Crop" [options]="cropOptions"
                      [disabled]="
                        i < initialCount ||
                        (i >= initialCount && !isADEEdit[i]) ||
                        isCropDisabled
                      "></p-dropdown>
                  </div>
                  <div class="form-group">
                    <label>Cycling Method:</label>
                    <p-dropdown placeholder="Select Cycling Method" [options]="cyclingMethodsoptions" formControlName="CylinderMethod"
                      [disabled]="!isADEEdit[i]"></p-dropdown>
                  </div>
                </div>
                <div class="form-row">
                  <div class="form-group">
                    <label>Endogenous Trait:</label>
                    <p-dropdown formControlName="EndogenousTrait" [disabled]="!isADEEdit[i]" [options]="section.data.endogenousTrait"
                      (onChange)="onEndogenousTrait($event.value, i)" placeholder="Select Endogenous Trait"></p-dropdown>
                  </div>
                  <div class="form-group">
                    <label>Instrument Category:</label>
                    <p-dropdown placeholder="Select Instrument Category" [options]="instrumentCategoryOptions" formControlName="InstrumentCategory"
                      [disabled]="!isADEEdit[i]"></p-dropdown>
                  </div>
                </div>
                <div class="form-row">
                  <div class="form-group">
                    <label>Tissue Type:</label>

                    <p-dropdown placeholder="Select Tissue Type" [options]="section.data.tissueTypeOptions" formControlName="TissueType" [disabled]="
                        i < initialCount ||
                        (i >= initialCount && !isADEEdit[i]) ||
                        isTissueTypeDisabled
                      " (onChange)="onTissueTypeChange($event.value, i)"></p-dropdown>
                  </div>

                  <div class="form-group">
                    <label>Scoring Method:</label>
                    <p-dropdown placeholder="Select Scoring Method" [options]="scoringMethodOptions" formControlName="ScoringMethod"
                      [disabled]="!isADEEdit[i]"></p-dropdown>
                  </div>
                </div>
                <div class="form-row">
                  <div class="form-group">
                    <label>Tissue Preparation:</label>
                    <p-dropdown placeholder="Select Tissue Preparation" [options]="section.data.tissuePreparationOptions"
                      formControlName="TissuePreparation" [disabled]="
                        i < initialCount ||
                        (i >= initialCount && !isADEEdit[i]) ||
                        isTissuePreparationDisabled
                      " (onChange)="onTissuePreparationChange($event.value, i)"></p-dropdown>
                  </div>

                  <div class="form-group">
                    <label>Baseline:</label>
                    <p-dropdown placeholder="Select Baseline" formControlName="Baseline" placeholder="Select Baseline" [options]="baselineOptions"
                      [disabled]="!isADEEdit[i]" (onChange)="onBaselineSelect($event.value, i)"></p-dropdown>
                  </div>
                </div>
                <div class="form-row">
                  <div class="form-group">
                    <label>Tissue Sampling:</label>
                    <!-- <input
                      pInputText
                      type="text"
                      [disabled]="true"
                      [value]="section.form.get('TissueSampling')?.value"
                    /> -->
                    <p-dropdown formControlName="TissueSampling" [options]="section.data.tissueSamplingOptions" placeholder="Select Tissue Sampling"
                      [disabled]="
                        i < initialCount ||
                        (i >= initialCount && !isADEEdit[i]) ||
                        isTissueSamplingDisabled
                      " (onChange)="onTissueSamplingChange($event, i)"></p-dropdown>
                  </div>

                  <div class="form-group">
                    <label> Baseline Start:</label>
                    <input pInputText formControlName="BaselineStart" [attr.disabled]="!isADEEdit[i] ?true :null" />
                  </div>
                </div>
                <div class="form-row">
                  <div class="form-group">
                    <label>Pooled Samples:</label>
                    <input type="checkbox" [disabled]="
                        i < initialCount ||
                        (i >= initialCount && !isADEEdit[i]) || !ispoolenable[i]
                      " [value]="section.form.get('PooledSamples')" [checked]="section.form.get('PooledSamples')?.value" />
                  </div>
                  <div class="form-group">
                    <label>Baseline End:</label>
                    <input pInputText formControlName="BaselineEnd" [attr.disabled]="!isADEEdit[i] ?true :null" />
                  </div>
                </div>
                <div class="form-row">
                  <div class="form-group">
                    <label>Assay Type:</label>
                    <p-dropdown placeholder="Select Assay Type" formControlName="AssayType" [options]="assayTypeOptions"
                      [disabled]="!isADEEdit[i]"></p-dropdown>
                  </div>
                  <div class="form-group">
                    <label>Threshold:</label>
                    <p-dropdown formControlName="Threshold" placeholder="Select Threshold" [options]="thresholdOptions" [disabled]="!isADEEdit[i]"
                      (onChange)="onThresholdSelect($event.value, i)"></p-dropdown>
                  </div>
                </div>
                <div class="form-row">
                  <div class="form-group">
                    <label>Extraction Type:</label>
                    <!-- <input
                        pInputText
                        type="text"
                        [disabled]="true"
                        [value]="section.form.get('ExtractionType')?.value"
                      /> -->
                    <p-dropdown placeholder="Extraction Type" [options]="extractionTypeOptions" formControlName="ExtractionType" [disabled]="
                        i < initialCount || (i >= initialCount && !isADEEdit[i])
                      "></p-dropdown>
                  </div>
                  <div class="form-group">
                    <label>Threshold Value:</label>
                    <input pInputText formControlName="ThresholdValue" [attr.disabled]="!isADEEdit[i] ?true :null" />
                  </div>
                </div>
                <div class="form-row">
                  <div class="form-group">
                    <label class="lable-margin-left" style="margin-right: 0.5%">Assay Mix Recipe Id:</label>
                    <input pInputText formControlName="AssayMixRecipeId" readonly />
                  </div>
                </div>
              </ng-container>
              <div class="row top-buffer10 trait-width">
                <div class="analysis-definition-analysis-header">
                  <ng-container>
                    <div>
                      <header>Traits</header>
                    </div>
                  </ng-container>
                </div>
                <div class="col-xs-12 col-md-6 top-buffer10" style="min-width: 48%" *ngFor="
                    let trait of getFiltered(section.data.selectedOptionTraits,i);
                    let first = first
                  ">
                  <div class="panel panel-primary">
                    <div class="panel-heading">
                      <div class="row">
                        <div class="col-xs-12 col-md-12">
                          <h6 class="panel-title">
                            <span>{{ trait.trait.name }}</span>
                            <div class="pull-right" *ngIf="">
                              <span class="pull-right">Combined Reagents</span>
                            </div>
                          </h6>
                        </div>
                      </div>
                    </div>
                    <div class="panel-body">
                      <div class="row top-buffer10">
                        <div class="col-xs-12 col-sm-6 form-row">
                          <div class="form-group">
                            <p>Gene Type:</p>
                          </div>

                          <div class="form-group">
                            <span>{{ trait.trait.geneType }}</span>
                          </div>
                        </div>
                      </div>
                      <div class="row top-buffer10">
                        <div class="col-xs-12 col-sm-6 form-row">
                          <div class="form-group">
                            <p>Dye:</p>
                          </div>

                          <div class="form-group">
                            <div class="form-group">
                              <span>{{ trait.dye.name }}</span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="row top-buffer10">
                        <div class="col-xs-12 col-sm-6 form-row">
                          <div class="form-group">
                            <p>Quencher:</p>
                          </div>
                          <div class="form-group">
                            <span>{{ trait.quencher.name }}</span>
                          </div>
                        </div>
                      </div>
                      <!-- Oligo Table -->
                      <div class="top-buffer10">
                        <table class="table table-striped table-bordered table-hover">
                          <thead>
                            <tr class="list-group-item-info">
                              <th class="list-group-item-heading">
                                Oligo Type
                              </th>
                              <th class="list-group-item-heading">Number</th>
                              <th class="list-group-item-heading">
                                Concentration (nM)
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>Forward</td>
                              <td>{{ trait.trait.forwardOligoNumber }}</td>
                              <td [attr.contenteditable]="isADEEdit[i]">
                                {{ trait.forwardOligoConcentration }}
                              </td>
                            </tr>
                            <tr>
                              <td>Reverse</td>
                              <td>{{ trait.trait.reverseOligoNumber }}</td>
                              <td [attr.contenteditable]="isADEEdit[i]">
                                {{ trait.reverseOligoConcentration }}
                              </td>
                            </tr>
                            <tr>
                              <td>Probe</td>
                              <td>{{ trait.trait.probeOligoNumber }}</td>
                              <td [attr.contenteditable]="isADEEdit[i]">
                                {{ trait.probeOligoConcentration }}
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div *ngIf="
                  isADEFormVisible &&
                  isEndogenousTraitSelect &&
                  i <= initialCount &&
                  i === selectedItem
                " class="row top-buffer10 trait-width">
                <div class="col-xs-12 col-md-6 top-buffer10" style="min-width: 48%">
                  <div class="panel panel-primary">
                    <div class="panel-heading">
                      <div class="row">
                        <div class="col-xs-12 col-md-12">
                          <h6 class="panel-title">
                            <span>{{ SelectedEndogenousTrait }}</span>
                          </h6>
                        </div>
                      </div>
                    </div>
                    <div class="panel-body">
                      <div class="row top-buffer10">
                        <div class="col-xs-12 col-sm-6 form-row">
                          <div class="form-group">
                            <p>Gene Type:</p>
                          </div>

                          <div class="form-group">
                            <span>{{ endogenousTraitGT }}</span>
                          </div>
                        </div>
                      </div>
                      <div class="row top-buffer10">
                        <div class="col-xs-12 col-sm-6 form-row">
                          <div class="form-group">
                            <p>Dye:</p>
                          </div>

                          <div class="form-group">
                            <p-dropdown [options]="dyeOptions" (onChange)="onDyeDropChanges()" placeholder="Select Dye"
                              style="font-size: 14px"></p-dropdown>
                          </div>
                        </div>
                      </div>
                      <div class="row top-buffer10">
                        <div class="col-xs-12 col-sm-6 form-row">
                          <div class="form-group">
                            <p>Quencher:</p>
                          </div>
                          <div class="form-group">
                            <p-dropdown [disabled]="isQuencherDisabled" [options]="statusOptions"></p-dropdown>
                          </div>
                        </div>
                      </div>
                      <div class="top-buffer10">
                        <table class="table table-striped table-bordered table-hover">
                          <thead>
                            <tr class="list-group-item-info">
                              <th class="list-group-item-heading">
                                Oligo Type
                              </th>
                              <th class="list-group-item-heading">Number</th>
                              <th class="list-group-item-heading">
                                Concentration (nM)
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>Forward</td>
                              <td>{{ endogenousTraitFN }}</td>
                              <td><input type="text" /></td>
                            </tr>
                            <tr>
                              <td>Reverse</td>
                              <td>{{ endogenousTraitRON }}</td>
                              <td><input type="text" /></td>
                            </tr>
                            <tr>
                              <td>Probe</td>
                              <td>{{ endogenousTraitPON }}</td>
                              <td><input type="text" /></td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
          <div class="button-container-save">
            <button pButton [disabled]=true label="Save" icon="pi pi-save" (click)="saveAdForm($event,i)" style="margin-left: 3px"></button>
          </div>
        </p-accordionTab>
      </p-accordion>
    </div>
  </div>
</div>
<ng-template #loading>
  <div class="loading-container">
    <div class="loading-icon"></div>
    <h2 style="text-align: end">Loading data</h2>
  </div>
</ng-template>
<div class="button-container-save" *ngIf="count > 0">
  <button class="final-cancel" pButton label="Cancel" icon="pi pi-times" (click)="cancelForm()"></button>
</div>
<p-toast></p-toast>