<!-- <div *ngIf="availableTraits.length>0   && displayedData.length>0"> -->
<div *ngIf="!isLoading">
  <ng-container>
    <header class="analysis-creation-header">
      Analysis Definition Creation

    </header>
  </ng-container>
  <div>
    <div class="summaryForm">
      <div class="summary-header">
        <ng-container>
          <header class="analysis-header">AD Summary</header>
          <!-- <button pButton type="button"  (click)="editAD()">
          Edit AD Summary
        </button> -->
        </ng-container>
      </div>

      <form [formGroup]="summaryForm" *ngIf="statusOptions.length > 0 && displayedData.length > 0; else loading">
        <ng-container>
          <div class="form-row">
            <div class="form-group">
              <label>Name:</label>
              <input pInputText [(ngModel)]="name" formControlName="adName" readOnly />
            </div>
            <div class="form-group">
              <label>Selected Traits:</label>
              <i class="fas fa-badge-check verified-icon"></i>
              <p-autoComplete formControlName="selectedTraits" [suggestions]="filteredData" (completeMethod)="filterTraits($event.query)"
                [multiple]="true" [(ngModel)]="selectedOptionTraits" (onUnselect)="updateName()" (onSelect)="limitTraitsSelections(); updateName()"
                style="margin-left: -6px" field="name" [virtualScroll]="false">
              </p-autoComplete>
              <i *ngIf="isADEButtonEnable" class="pi pi-verified verified-icon"></i>
            </div>
          </div>
          <div class="form-row">
            <div class="form-group">
              <label class="assay-mrg-rt">Assay Oligo Box:</label>
              <input pInputText type="text" formControlName="assayOligo" />
            </div>
            <div class="form-group">
              <label class="status-mrg-rt">AD Status:</label>
              <p-dropdown placeholder="Select Status" formControlName="adStatus" optionValue="id" optionLabel="status" [options]="statusOptions"
                [ngModel]="statusOptions[0].id" (onChange)="onStatusChange($event.value)" [disabled]="isEditOpen"></p-dropdown>
            </div>
          </div>
          <div class="form-row">
            <div class="form-group">
              <label class="crtby-mrg-rt">Created By:</label>
              <input readonly pInputText formControlName="createdBy" />
            </div>

            <div class="form-group">
              <label class="crton-mrg-rt">Created On:</label>
              <input pInputText type="text" [value]="
                summaryForm.get('createdOn')?.value | date : 'MM/dd/yyyy'
              " readonly />
            </div>
          </div>
        </ng-container>
      </form>
      <ng-template #loading>
        <div class="loading-container">
          <div class="loading-icon"></div>
          <h2 style="text-align: end">Loading data</h2>
        </div>
      </ng-template>
      <div class="button-container-save">
        <button pButton class="validate-button" *ngIf="
          statusOptions.length > 0 &&
          displayedData.length > 0 &&
          !isADEButtonEnable
        " label="Validate" icon="pi pi-shield" (click)="validateForm()"></button>
      </div>
    </div>

    <div class="Analysis-Definition-Entries-Container">
      <div class="analysis-definition-analysis-header">
        <ng-container>
          <div class="header-width">
            <header class="analysis-header">Analysis Definition Entries</header>
          </div>
          <div class="count-btn-width">
            <button class="count-button">{{ count }}</button>
          </div>
          <div>
            <button class="newADE-width" pButton type="button" (click)="formADEVisibility()" [disabled]="!isADEButtonEnable">
              + Add New ADE
            </button>
          </div>
        </ng-container>`
      </div>
      <div *ngIf="isADEFormVisible" class="ADEHeaderStyle">
        <ng-container *ngIf="isADEFormVisible">
          <div class="header-width ade-header-width">
            <button class="button-container" *ngIf="adeForm.get('Crop')?.value" class="selected-value-button">
              {{ adeForm.get("Crop")?.value }}
            </button>
            <button class="button-container" *ngIf="adeForm.get('AssayType')?.value" class="selected-value-button">
              {{ adeForm.get("AssayType")?.value }}
            </button>
            <button class="button-container" *ngIf="adeForm.get('TissueType')?.value" class="selected-value-button">
              {{ adeForm.get("TissueType")?.value }}
            </button>
            <button class="button-container" *ngIf="adeForm.get('TissuePreparation')?.value" class="selected-value-button">
              {{ adeForm.get("TissuePreparation")?.value }}
            </button>
            <button class="button-container" *ngIf="adeForm.get('TissueSampling')?.value" class="selected-value-button">
              {{ adeForm.get("TissueSampling")?.value }}
            </button>
          </div>
          <div class="ADE-header-align">
            <button class="button-container" *ngIf="adeForm.get('adeStatus')?.value" class="selected-value-button">
              {{ adeForm.get("adeStatus")?.value }}
            </button>
          </div>
          <!-- <div>
          <button
            pButton
            type="button"
            class="newManage-ADE"
            (mouseover)="showOptions = true"
            (click)="menu.toggle($event)"
          >
            Mange ADE
          </button>
          <p-menu #menu [model]="adeOptions" [popup]="true"></p-menu>
        </div> -->
        </ng-container>
      </div>
      <div>
        <form class="ADEFormStyleBorder" [formGroup]="adeForm" *ngIf="isADEFormVisible">
          <ng-container>
            <div class="form-row">
              <div class="form-group">
                <label>ADE Status:</label>
                <p-dropdown formControlName="adeStatus" placeholder="Select Status" [options]="adeStatusOptions"
                  [disabled]="!isADEEditOpen"></p-dropdown>
              </div>
              <div class="form-group">
                <label>Last Modified By:</label>
                <input pInputText type="text" [value]="adeForm.get('lastModifiedBy')?.value" readonly />
              </div>
            </div>
            <div class="form-row">
              <div class="form-group">
                <label>Assay Group:</label>
                <p-multiSelect formControlName="assayGroup" (onClick)="onAssayGroupChange()" [options]="assayGroups" [disabled]="!isADEEditOpen"
                  [(ngModel)]="SelectedAssayGroupOptions">
                </p-multiSelect>
              </div>
              <div class="form-group">
                <label class="ade-modDt-mrg-rt">Last Modified Date:</label>
                <input pInputText type="text" [value]="
                  adeForm.get('lastModifiedDate')?.value | date : 'MM/dd/yyyy'
                " readonly />
              </div>
            </div>
            <div class="form-row">
              <div class="form-group">
                <label>Backbone:</label>
                <p-dropdown formControlName="backbone" placeholder="Select Backbone" [options]="backboneOptions"
                  [disabled]="!isADEEditOpen"></p-dropdown>
              </div>
              <div class="form-group">
                <label>Control Template:</label>
                <p-dropdown placeholder="Select Control Template" [options]="controlTemplateOptions" formControlName="ControlTemplate"
                  [disabled]="!isADEEditOpen"></p-dropdown>
              </div>
            </div>
            <div class="form-row">
              <div class="form-group">
                <label>Lab:</label>
                <p-dropdown placeholder="Select Lab" [options]="labOptions" formControlName="Lab" optionLabel="name" [disabled]="!isADEEditOpen"
                  (onChange)="onLabChange($event.value)"></p-dropdown>
              </div>
              <div class="form-group">
                <label>Master Mix:</label>
                <p-dropdown placeholder="Select Master Mix" [options]="masterMixOptions" optionLabel="description" formControlName="MasterMix"
                  [disabled]="!isADEEditOpen"></p-dropdown>
              </div>
            </div>
            <div class="form-row">
              <div class="form-group">
                <label>Crop:</label>
                <p-dropdown formControlName="Crop" placeholder="Select Crop" [options]="cropOptions" [disabled]="!isADEEditOpen"
                  (onChange)="onCropChange($event.value)"></p-dropdown>
              </div>
              <div class="form-group">
                <label>Cycling Method:</label>
                <p-dropdown placeholder="Select Cycling Method" [options]="cyclingMethodsoptions" optionLabel="description"
                  formControlName="CylinderMethod" [disabled]="!isADEEditOpen"></p-dropdown>
              </div>
            </div>
            <div class="form-row">
              <div class="form-group">
                <label>Endogenous Trait:</label>
                <p-dropdown formControlName="EndogenousTrait" [disabled]="!selectedCrop" [options]="endogenousTrait" (onChange)="onEndogenousTrait()"
                  placeholder="Select Endogenous Trait" [(ngModel)]="SelectedEndogenousTrait"></p-dropdown>
              </div>
              <div class="form-group">
                <label>Instrument Category:</label>
                <p-dropdown placeholder="Select Instrument Category" [options]="instrumentCategoryOptions" optionLabel="name"
                  formControlName="InstrumentCategory" [disabled]="!isADEEditOpen"></p-dropdown>
              </div>
            </div>
            <div class="form-row">
              <div class="form-group">
                <label>Tissue Type:</label>
                <p-dropdown placeholder="Select Tissue Type" [options]="tissueTypeOptions" formControlName="TissueType" [disabled]="!isADEEditOpen"
                  (onChange)="onTissueTypeChange($event.value)"></p-dropdown>
              </div>

              <div class="form-group">
                <label>Scoring Method:</label>
                <p-dropdown placeholder="Select Scoring Method" [options]="scoringMethodOptions" optionLabel="name" option
                  formControlName="ScoringMethod" [disabled]="!isADEEditOpen"></p-dropdown>
              </div>
            </div>
            <div class="form-row">
              <div class="form-group">
                <label>Tissue Preparation:</label>
                <p-dropdown placeholder="Select Tissue Preparation" [options]="tissuePreparationOptions" formControlName="TissuePreparation"
                  [disabled]="!isADEEditOpen" (onChange)="onTissuePreparationChange($event.value)"></p-dropdown>
              </div>

              <div class="form-group">
                <label>Baseline:</label>
                <p-dropdown placeholder="Select Baseline" formControlName="Baseline" placeholder="Select Baseline" [options]="baselineOptions"
                  [disabled]="!isADEEditOpen"></p-dropdown>
              </div>
            </div>
            <div class="form-row">
              <div class="form-group">
                <label>Tissue Sampling:</label>
                <p-dropdown formControlName="TissueSampling" [options]="tissueSamplingOptions" placeholder="Select Tissue Sampling"
                  [disabled]="!isADEEditOpen"></p-dropdown>
              </div>

              <div class="form-group">
                <label> Baseline Start:</label>
                <input pInputText formControlName="BaselineStart" [readonly]="!isADEEditOpen" />
              </div>
            </div>
            <div class="form-row">
              <div class="form-group">
                <label>Pooled Samples:</label>
                <input type="checkbox" formControlName="PooledSamples" [readonly]="!isADEEditOpen" />
              </div>

              <div class="form-group">
                <label>Baseline End:</label>
                <input pInputText formControlName="BaselineEnd" [readonly]="!isADEEditOpen" />
              </div>
            </div>
            <div class="form-row">
              <div class="form-group">
                <label>Assay Type:</label>
                <p-dropdown placeholder="Select Assay Type" formControlName="AssayType" [options]="assayTypeOptions"
                  [disabled]="!isADEEditOpen"></p-dropdown>
              </div>
              <div class="form-group">
                <label>Threshold:</label>
                <p-dropdown formControlName="Threshold" placeholder="Select Threshold" [options]="thresholdOptions"
                  [disabled]="!isADEEditOpen"></p-dropdown>
              </div>
            </div>
            <div class="form-row">
              <div class="form-group">
                <label>Extraction Type:</label>
                <p-dropdown placeholder="Extraction Type" [options]="extractionTypeOptions" formControlName="ExtractionType"
                  [disabled]="!isADEEditOpen"></p-dropdown>
              </div>
              <div class="form-group">
                <label>Threshold Value:</label>
                <input pInputText formControlName="ThresholdValue" [readonly]="!isADEEditOpen" />
              </div>
            </div>
            <div class="form-row">
              <div class="form-group">
                <label class="lable-margin-left" style="margin-right: 0.5%">Assay Mix Recipe Id:</label>
                <input pInputText formControlName="AssayMixRecipeId" readonly />
              </div>
            </div>
          </ng-container>
          <div *ngIf="isADEFormVisible" class="row top-buffer10 trait-width">
            <div class="analysis-definition-analysis-header">
              <ng-container>
                <div>
                  <header>Traits</header>
                </div>
              </ng-container>
            </div>
            <div class="col-xs-12 col-md-6 top-buffer10" style="min-width: 48%" *ngFor="let trait of selectedOptionTraits let i = index">
              <div class="panel panel-primary">
                <div class="panel-heading">
                  <div class="row">
                    <div class="col-xs-12 col-md-12">
                      <h6 class="panel-title">
                        <span>{{ trait.name }}</span>
                        <span class="pull-right" *ngIf="">Combined Reagents</span>
                      </h6>
                    </div>
                  </div>
                </div>
                <div class="panel-body">
                  <div class="row top-buffer10">
                    <div class="col-xs-12 col-sm-6 form-row">
                      <div class="form-group">
                        <p>Gene Type:</p>
                      </div>

                      <div class="form-group">
                        <span>{{ trait.geneType }}</span>
                      </div>
                    </div>
                  </div>
                  <div class="row top-buffer10">
                    <div class="col-xs-12 col-sm-6 form-row">
                      <div class="form-group">
                        <p>Dye:</p>
                      </div>

                      <div class="form-group">
                        <p-dropdown [options]="dyeOptions" (onChange)="onDyeDropChanges(i)" placeholder="Select Dye"
                          formControlName="dye{{i}}"></p-dropdown>
                      </div>
                    </div>
                  </div>
                  <div class="row top-buffer10">
                    <div class="col-xs-12 col-sm-6 form-row">
                      <div class="form-group">
                        <p>Quencher:</p>
                      </div>
                      <div class="form-group">
                        <p-dropdown [options]="quencherOptions" placeholder="Select Quencher" formControlName="quencher{{i}}"
                          [disabled]="true"></p-dropdown>
                      </div>
                    </div>
                  </div>
                  <div class="top-buffer10">
                    <table class="table table-striped table-bordered table-hover">
                      <thead>
                        <tr class="list-group-item-info">
                          <th class="list-group-item-heading">Oligo Type</th>
                          <th class="list-group-item-heading">Number</th>
                          <th class="list-group-item-heading">
                            Concentration (nM)
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>Forward</td>
                          <td>{{ trait.forwardOligoNumber }} </td>
                          <td><input formControlName="forwardOligoConcentration{{i}}" type="text" /></td>
                        </tr>
                        <tr>
                          <td>Reverse</td>
                          <td>{{ trait.reverseOligoNumber }}</td>
                          <td><input formControlName="probeOligoConcentration{{i}}" type="text" /></td>
                        </tr>
                        <tr>
                          <td>Probe</td>
                          <td>{{ trait.probeOligoNumber }}</td>
                          <td><input formControlName="reverseOligoConcentration{{i}}" type="text" /></td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div *ngIf="isADEFormVisible && isEndogenousTraitSelect" class="row top-buffer10 trait-width">
            <div class="col-xs-12 col-md-6 top-buffer10" style="min-width: 48%">
              <div class="panel panel-primary">
                <div class="panel-heading">
                  <div class="row">
                    <div class="col-xs-12 col-md-12">
                      <h6 class="panel-title">
                        <span>{{ SelectedEndogenousTrait }}</span>
                      </h6>
                    </div>
                  </div>
                </div>
                <div class="panel-body">
                  <div class="row top-buffer10">
                    <div class="col-xs-12 col-sm-6 form-row">
                      <div class="form-group">
                        <p>Gene Type:</p>
                      </div>
                      <div class="form-group">
                        <span>{{ endogenousTraitGT }}</span>
                      </div>
                    </div>
                  </div>
                  <div class="row top-buffer10">
                    <div class="col-xs-12 col-sm-6 form-row">
                      <div class="form-group">
                        <p>Dye:</p>
                      </div>

                      <div class="form-group">
                        <p-dropdown [options]="dyeOptions" placeholder="Select Dye" formControlName="endogenousDye"></p-dropdown>
                      </div>
                    </div>
                  </div>
                  <div class="row top-buffer10">
                    <div class="col-xs-12 col-sm-6 form-row">
                      <div class="form-group">
                        <p>Quencher:</p>
                      </div>
                      <div class="form-group">
                        <p-dropdown [disabled]="isQuencherDisabled" [options]="quencherOptions" value="status"
                          formControlName="endogenousQuencher"></p-dropdown>
                      </div>
                    </div>
                  </div>
                  <div class="top-buffer10">
                    <table class="table table-striped table-bordered table-hover">
                      <thead>
                        <tr class="list-group-item-info">
                          <th class="list-group-item-heading">Oligo Type</th>
                          <th class="list-group-item-heading">Number</th>
                          <th class="list-group-item-heading">
                            Concentration (nM)
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>Forward</td>
                          <td>{{ endogenousTraitFN }}</td>
                          <td><input formControlName="endogeniousForward" type="text" /></td>
                        </tr>
                        <tr>
                          <td>Reverse</td>
                          <td>{{ endogenousTraitRON }}</td>
                          <td><input formControlName="endogeniousProbe" type="text" /></td>
                        </tr>
                        <tr>
                          <td>Probe</td>
                          <td>{{ endogenousTraitPON }}</td>
                          <td><input formControlName="endogeniousReverse" type="text" /></td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
    <div class="button-container-save" *ngIf="isADEButtonEnable && count > 0">
      <button pButton label="Save" icon="pi pi-save" style="margin-left: 3px" (click)="saveForm()"></button>
      <button class="final-cancel" pButton label="Cancel" icon="pi pi-times" (click)="cancelForm()" routerLink="/analysis-definition"></button>
    </div>
  </div>
</div>
<div *ngIf="isLoading">
  <div class="loading-container">
    <div class="loading-icon"></div>
    <h2 style="text-align: end">saving data</h2>
  </div>
</div>

<p-toast></p-toast>