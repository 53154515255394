import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

@Component({
  selector: 'app-error-dialog',
  templateUrl: './existing-ad-error-dialog.component.html',
})
//  Use of This is used only for the Duplicate AD redirects to the existing AD Details
export class ExistingADErrorDialogComponent {
  errorMessage: String = '';
  duplicateAnalysisId: any = '';
  constructor(
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
    private router: Router
  ) {
    this.errorMessage = this.config.data.errorMessage;
    this.duplicateAnalysisId = this.config.data.duplicateAnalysisId;
  }

  navigateToDetails() {
    sessionStorage.setItem('validateDuplicate', 'true');
    this.router.navigate(['/analysis-definition-details'], {
      queryParams: { AnalysisId: this.duplicateAnalysisId },
    });
    this.ref.close();
  }
}
