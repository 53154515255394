import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, mergeMap } from 'rxjs';
import { config } from '../config';
import { AuthService } from '../services/auth/auth.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(private authService: AuthService) { }

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpEvent<any>> {
    if (!request.url.startsWith(config.apiBaseUri)) {

    }
    const accessToken = sessionStorage.getItem('accessToken');

    if (accessToken) {
      const newRequest = request.clone({
        headers: request.headers.set("Authorization", `Bearer ${accessToken}`)
      });
      return next.handle(newRequest);
    }
    else {
      return this.authService.accessToken$.pipe(mergeMap(accessToken => {
        sessionStorage.setItem('accessToken', accessToken);
        const newRequest = request.clone({
          headers: request.headers.set("Authorization", `Bearer ${accessToken}`)
        });

        return next.handle(newRequest);
      }));
    }
  }
}
