import { Component } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-login-component',
  templateUrl: './login-component.component.html',
  styleUrls: ['./login-component.component.scss'],
})
export class LoginComponentComponent {
  supportUrl = environment.supportUrl;

  constructor() { }

  redirectToUrl() {
    window.open(environment.userguideurl, "_blank");
  }

}
