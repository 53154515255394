import { Component, OnInit } from '@angular/core';
import { Lab } from 'src/app/interfaces/lab';
import { LabService } from 'src/app/services/lab/lab.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-labs',
  templateUrl: './labs.component.html',
  styleUrls: ['./labs.component.scss'],
})
export class LabsComponent implements OnInit {
  public labs$: Observable<Lab[]>;

  constructor(private LabService: LabService) {
    this.labs$ = this.LabService.getByQuery();
  }

  ngOnInit(): void {}
}
