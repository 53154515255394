import { AuthService } from './services/auth/auth.service';
import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  title = 'encompass.iq.ui';

  constructor(authService: AuthService,
    public router: Router) {
    authService.init();
  }
  isRouteActive(routePath: string): boolean {
    return this.router.url === routePath;
  }
}
