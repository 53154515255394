import { config } from '../../config';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {Observable } from 'rxjs';
import { ADEntries } from 'src/app/interfaces/ad-entries';
import { AnalysisDefinition } from './analysis-definition-details';

@Injectable({
    providedIn: 'root',
})
export class AnalysisDefinitionDetailsRetrieveService {
    private uri = config.apiBaseUri + 'analysisDefinitions/';
    private data: any[] = [];

    constructor(private http: HttpClient) {}

    getDetails(ade: string): Observable<AnalysisDefinition> {
        const url = `${this.uri}${ade}`;
        return this.http.get<AnalysisDefinition>(url);
    }
}
