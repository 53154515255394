// TO-DO: Use JSON.parse to extract Id's value from the database and use them in this file (API call to get the Id's value)

export const TISSUE_TYPES_ID_NAME_MAP = {
  'Seed': '73602F51-B7FA-E611-80F9-005056B07DA3',
  'Coleoptile': 'D6152B5D-B7FA-E611-80F9-005056B07DA3',
  'Callus': '98E72175-51CF-E511-8E5C-005056BC0200',
  'Leaf': '99E72175-51CF-E511-8E5C-005056BC0200',
  'Root': '9AE72175-51CF-E511-8E5C-005056BC0200',
  'Other': '9BE72175-51CF-E511-8E5C-005056BC0200',
  'Cotyledon': 'CDEDAAF8-2ED7-4AAB-8204-88D819C671E9',
};

export const TISSUE_PREPARATIONS_ID_NAME_MAP = {
  'Cut': 'F76C5994-CF97-4C77-AB4B-08B8E72FF9EF',
  'Other': '98747CF6-8B5C-41D9-93D5-094B41A4118A',
  'Whole Embryo': 'C7A9C991-2275-4704-A3FB-0A579577DC56',
  'Peeled': 'BB44A98D-1C47-413C-9B70-160C468474E9',
  'Multiple': 'A2BD8694-7BB4-42DC-9215-3AB887429BB2',
  'Wet': '62CC74D8-F7EA-439A-AF29-4407739DCDD8',
  'SSP': '1FD7ECDE-1300-409E-88BF-44AF66AA51ED',
  'Punch': '651FAD41-7F40-475B-9B15-4547B06E3AD3',
  'Seed': 'F7D90749-6331-412C-8D6D-473A90E36992',
  'CBS': 'F0052DC8-ABE2-4AC0-8ED3-4E609BF99700',
  'Punch Illumina': '8C7C1224-32BF-4271-B6C2-5294420434C4',
  'Single': '2308EF8E-1A54-42FD-9F5B-53142557C005',
  'Pinch': 'A34CFBCC-D82C-44AC-A6AA-53FA20B6BDDA',
  'Half': '53EB9087-15F0-428E-919F-73ED888626E5',
  'Whole': '38CF9E74-5094-446B-8DF8-87DEAA1B4204',
  'Piece': '8F21C314-74AD-4164-A10B-96F25E1EF7B5',
  'Crushed': 'EB29EF6F-64F1-4979-851A-A811A136E3D2',
  'Flour': '1B62B489-D955-43B0-9995-B8D95DDF2AB6',
  'Bulk Multiple Plants': 'F9963E07-6F52-470C-9092-B8E5E8233895',
  'Chip': '24C44AA3-FD98-45F5-BAE9-BD3B2B1F95F8',
  'Dried': '711E3B6F-514A-4475-85EF-C5240FFB6B7D',
  'Bulk Single Plant': 'ACAA8157-EC50-4667-A39C-FD72E5C47DF9',
};

export const CROP_TYPES_ID_NAME_MAP = {
  'Cowpea': 'A1A0C592-2AD6-E711-8102-005056B07DA3',
  'Miscellaneous': '0A27BBD4-25A8-E911-812A-005056B07DA3',
  'Pea': '582FAE37-2C84-EA11-8131-005056B07DA3',
  'Mustard': '067E65B9-8E78-EB11-813D-005056B07DA3',
  'Alfalfa': '34D349D8-77DA-E511-9A95-005056BC0200',
  'Animal': '35D349D8-77DA-E511-9A95-005056BC0200',
  'Arabidopsis': '36D349D8-77DA-E511-9A95-005056BC0200',
  'Bok Choy': '37D349D8-77DA-E511-9A95-005056BC0200',
  'Bush Bean': '38D349D8-77DA-E511-9A95-005056BC0200',
  'Canola': '39D349D8-77DA-E511-9A95-005056BC0200',
  'Corn': '3AD349D8-77DA-E511-9A95-005056BC0200',
  'Cotton': '3BD349D8-77DA-E511-9A95-005056BC0200',
  'DNA': '3CD349D8-77DA-E511-9A95-005056BC0200',
  'Feed': '3DD349D8-77DA-E511-9A95-005056BC0200',
  'Green Foxtail': '3ED349D8-77DA-E511-9A95-005056BC0200',
  'Insect': '3FD349D8-77DA-E511-9A95-005056BC0200',
  'Microbe': '40D349D8-77DA-E511-9A95-005056BC0200',
  'Millet': '41D349D8-77DA-E511-9A95-005056BC0200',
  'Milkweed': '42D349D8-77DA-E511-9A95-005056BC0200',
  'Rice': '43D349D8-77DA-E511-9A95-005056BC0200',
  'Sorghum': '44D349D8-77DA-E511-9A95-005056BC0200',
  'Soybean': '45D349D8-77DA-E511-9A95-005056BC0200',
  'Sugarcane': '46D349D8-77DA-E511-9A95-005056BC0200',
  'Sunflowers': '47D349D8-77DA-E511-9A95-005056BC0200',
  'Tobacco': '48D349D8-77DA-E511-9A95-005056BC0200',
  'Wheat': '49D349D8-77DA-E511-9A95-005056BC0200',
  'Pepper': 'D47BD8A8-73BF-EC11-814E-B6CBF8A90EA5',
};

export const ASSAY_TYPES_ID_NAME_MAP = {
  'Relative Expression': '882558E6-BCFA-E611-80F9-005056B07DA3',
  'Presence/Absence': '89E92175-51CF-E511-8E5C-005056BC0200',
  'Copy Number': '8AE92175-51CF-E511-8E5C-005056BC0200',
  'Zygosity': '8BE92175-51CF-E511-8E5C-005056BC0200',
};

export const EXTRACTION_METHODS_ID_NAME_MAP = {
  'Clean Prep': '1798DFB7-8630-E711-80FB-005056B07DA3',
  'Sbeadex': '1898DFB7-8630-E711-80FB-005056B07DA3',
  'EZNA': '5E6EA5BE-8630-E711-80FB-005056B07DA3',
  'One Step': 'C23A866A-B1D6-E711-8102-005056B07DA3',
  'Hot Shot': '88E92175-51CF-E511-8E5C-005056BC0200',
  'Turbo Shot': '134E1B55-5DCF-4E25-8168-5E5FEC5E6C1C',
  'RNeasy': 'B800798B-20C1-46AC-80FC-65B4B9A17957',
};

export const CONTROL_TEMPLATES_ID_NAME_MAP = {
  'INACTIVE_PresenceAbsence_4_pos': '4e91cb79-d05f-e611-80f5-005056b07da3',
  'Zygosity_duplex_hemi': '63555507-61ff-e611-80f9-005056b07da3',
  'CopyNumber_Duplex_CAL1_2_3': 'db20885a-6020-e711-80fa-005056b07da3',
  'Zygosity_duplex_homo': '21555fc3-e94a-e711-80fc-005056b07da3',
  'Alternate_CopyNumber': '8170a56d-f541-ea11-812e-005056b07da3',
  'Alternate_PresenceAbsence': '4741b4ac-f541-ea11-812e-005056b07da3',
  'Alternate_Zygosity': '2486bbda-f541-ea11-812e-005056b07da3',
  'Alternate_PresenceAbsence_2negs': 'f1741e67-ee6d-ea11-8130-005056b07da3',
  'CopyNumber_Duplex': '67cf6d75-7bda-e511-9a95-005056bc0200',
  'PresenceAbsense_Duplex': '7fd69840-a7e0-e511-ad57-005056bc0200',
  'Zygosity_Duplex': '08adb264-a7e0-e511-ad57-005056bc0200',
  'SPLEX_2GOI': '1ab3f1af-a7e0-e511-ad57-005056bc0200',
  'SPLEX_3GOI': '9aa68ed9-a7e0-e511-ad57-005056bc0200',
  'SPLEX_4GOI': '4a005afb-a7e0-e511-ad57-005056bc0200',
  'SPLEX_5GOI': '683a951a-a8e0-e511-ad57-005056bc0200',
  'CopyNumber_Duplex_Cal1': '9ac6608c-a8e0-e511-ad57-005056bc0200',
  'CopyNumber_Duplex_Cal2': '3cf1e58b-abe0-e511-ad57-005056bc0200',
  '4pp_presenceabsence': 'ba645528-c221-e611-b972-005056bc0200',
};

export const TISSUE_SAMPLINGS_ID_NAME_MAP = {
  '6': '293c7545-72c3-4c17-870f-07f905041fd1',
  '7': '1c942c4f-c32a-4ad1-a203-279a42047c1d',
  '9': '1a735125-2b0b-4227-85ec-2a176d05b924',
  '5': 'b98ee5f7-f494-490a-b7b5-b49c3b5a318a',
  '1': '7ac0cc49-8b13-4d36-9f0a-b584c021ab08',
  '3': '79f721ac-fd4f-4634-8fe4-be7a040b35ef',
  '20': 'cba3865f-276d-4280-8694-c11f720db880',
  '8': '420dbf1d-60b7-404e-a221-c88697efa41a',
  'NULL': 'cfa60296-83bf-4bc9-9ce1-d6baa83b4b2c',
  '10': 'a7bf0c54-fc8d-4387-a125-f048ab9d06f1',
  '4': 'f28acfc3-96c6-4c35-b765-f291e556a011',
  '2': 'fbdca0a6-73ec-4d83-894d-f45b662db092',
};

export const ANALYSIS_MODES_ID_NAME_MAP = {
  'Manual': 'dacb4176-c106-46a4-b1b6-394ba01a1bad',
  'Automatic': '83a81cf9-7041-4ffd-a5b9-982ac202df83'
}
