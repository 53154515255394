import { LabsComponent } from './pages/labs/labs.component';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DashboardOptionsComponent } from './dashboard-options/dashboard-options.component';
import { AnalysisDefinitionComponent } from './analysis-definition/analysis-definition.component';
import { AnalysisDefinitionCreationComponent } from './analysis-definition-creation/analysis-definition-creation.component';
import { AnalysisDefinitionDetailsComponent } from './analysis-definition-details/analysis-definition-details.component';
import { EndogenousCropAssaysComponent } from './endogenous-crop-assays/endogenous-crop-assays.component';
import { AuthService } from './services/auth/auth.service';
import { LoginComponentComponent } from './login-component/login-component.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full',
  },
  {
    path: 'login',
    component: LoginComponentComponent,
  },
  {
    path: 'labs',
    component: LabsComponent,
    canActivate: [AuthService],
  },
  {
    path: 'dashboard-options',
    component: DashboardOptionsComponent,
  },
  {
    path: 'analysis-definition',
    component: AnalysisDefinitionComponent,
    canActivate: [AuthService],
  },
  {
    path: 'analysis-definition-creation',
    component: AnalysisDefinitionCreationComponent,
    canActivate: [AuthService],
  },
  {
    path: `analysis-definition-details`,
    component: AnalysisDefinitionDetailsComponent,
    canActivate: [AuthService],
  },
  {
    path: 'endogenous-crop-assays',
    component: EndogenousCropAssaysComponent,
    canActivate: [AuthService],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
