import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { MsalService } from '@azure/msal-angular';
import { AccountInfo } from '@azure/msal-common';
import { UserInformationService } from '@corteva-research/ngx-components-core';
import {
  Observable,
  ReplaySubject,
  Subject,
  distinctUntilChanged,
  map,
} from 'rxjs';
import { config } from '../../config';

@Injectable({
  providedIn: 'root',
})
export class AuthService implements CanActivate {
  accessToken$: Observable<string>;
  accountInfo$: Observable<AccountInfo>;

  private accessTokenSubject: Subject<string> = new ReplaySubject<string>(1);
  private accountInfoSubject: Subject<AccountInfo> =
    new ReplaySubject<AccountInfo>(1);

  constructor(
    private authService: MsalService,
    public userInformationService: UserInformationService,
    private router: Router
  ) {
    this.accessToken$ = this.accessTokenSubject
      .asObservable()
      .pipe(distinctUntilChanged());
    this.accountInfo$ = this.accountInfoSubject.asObservable();
    this.authService.instance.initialize().then(() => {
      this.init();
    });
  }

  init() {
    const accounts = this.authService.instance.getAllAccounts();
    let isAuthenticated: boolean = false;
    let isLoggingIn: boolean = false;
    if (accounts.length > 0) {
      const account = accounts[0];
      this.authService.instance.setActiveAccount(account);
      this.setUserInfo(account);
      isAuthenticated = true;
    }

    const sessionAuthStatus = sessionStorage.getItem('authStatus');
    if (sessionAuthStatus === 'authenticated') {
      this.userInformationService.setAuthenticationStatus('authenticated');
      const userInfo = JSON.parse(sessionStorage.getItem('userInfo') || '{}');
      if (userInfo && userInfo.displayName && userInfo.uniqueId) {
        this.userInformationService.setUserInformation(userInfo);
      }
      if (
        sessionStorage.getItem('CatcheData') == null &&
        sessionStorage.getItem('analysisDefinitionDetailData') == null
      ) {
        if (sessionStorage.getItem('authenticationResult')) {
          const authenticationResult = JSON.parse(
            sessionStorage.getItem('authenticationResult') || '{}'
          );
          this.setUserInfo(authenticationResult.account);
          this.authService.instance.setActiveAccount(
            authenticationResult.account
          );
          this.accessTokenSubject.next(authenticationResult.accessToken);
          this.router.navigate(['/analysis-definition']);
        }
      }
    }

    this.refreshToken();
    setInterval(() => this.refreshToken(), config.accessTokenRefreshInterval);

    this.userInformationService.enableAuthentication();
    this.userInformationService.loginRequest$.subscribe(() => {

      if (!isLoggingIn) {
        isLoggingIn = true;
        this.authService.loginPopup().subscribe(
          (authenticationResult) => {
            sessionStorage.setItem(
              'authenticationResult',
              JSON.stringify(authenticationResult)
            );
            this.setUserInfo(authenticationResult.account);
            this.authService.instance.setActiveAccount(
              authenticationResult.account
            );
            this.accessTokenSubject.next(authenticationResult.accessToken);
            isLoggingIn = false;
            this.router.navigate(['/analysis-definition']);
            sessionStorage.setItem('authStatus', 'authenticated');
            sessionStorage.setItem(
              'userInfo',
              JSON.stringify({
                displayName:
                  authenticationResult.account.name || 'Unknown User',
                uniqueId:
                  authenticationResult.account.username || 'Unknown User',
                avatarUri: '',
              })
            );
          },
          (error) => {
            isLoggingIn = false;
          }
        );
      }
    });

    this.userInformationService.logoutRequest$.subscribe(() =>
      this.authService.logoutPopup().subscribe(() => {
        this.afterLogout();
        this.router.navigate(['/login']);
      })
    );
  }

  private refreshToken(): void {
    const activeAccount = this.authService.instance.getActiveAccount();
    if (activeAccount === null) {
      return;
    }

    this.authService
      .acquireTokenSilent({ scopes: [] })
      .subscribe((authenticationResult) => {
        this.accessTokenSubject.next(authenticationResult.accessToken);
      });
  }

  private setUserInfo(accountInfo: AccountInfo | null): void {
    this.userInformationService.setAuthenticationStatus('authenticated');
    this.userInformationService.setUserInformation({
      displayName: accountInfo?.name || 'Unknown User',
      uniqueId: accountInfo?.username || 'Unknown User',
      avatarUri: '',
    });
    sessionStorage.setItem('authStatus', 'authenticated');
    sessionStorage.setItem(
      'userInfo',
      JSON.stringify({
        displayName: accountInfo?.name || 'Unknown User',
        uniqueId: accountInfo?.username || 'Unknown User',
        avatarUri: '',
      })
    );
  }

  private afterLogout() {
    this.userInformationService.setAuthenticationStatus('unauthenticated');
    sessionStorage.removeItem('authStatus');
    this.router.navigate(['/login']);
  }
  canActivate(): Observable<boolean> {
    return this.userInformationService.authStatus$.pipe(
      map((status: string) => status === 'authenticated')
    );
  }
  public isAuthenticated(): Observable<boolean> {
    return this.userInformationService.authStatus$.pipe(
      map((status) => status === 'authenticated')
    );
  }
}
