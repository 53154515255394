import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { config } from '../../config';
import { Traits, traitsInfo } from './api-traits';

@Injectable({
  providedIn: 'root',
})
export class TraitsRetriveService {
  private traitsInfoUri = config.apiBaseUri + 'traits';

  constructor(private http: HttpClient) { }

  GetTraitsList = (): Observable<Traits[]> => {
    return this.http.get<Traits[]>(this.traitsInfoUri + '/allTraitNames');
  };

  GetTraitInfo = (traitId: string): Observable<traitsInfo> => {
    return this.http.get<traitsInfo>(this.traitsInfoUri + '/' + traitId);
  }
}
