import { environment } from "src/environments/environment";

export const config = {
  localapiBaseUri: 'https://localhost:7101/api/',
  apiBaseUri: environment.apiBaseUri,
  uiBaseUri: environment.uiBaseUri,
  clientId: environment.clientId,
  authority: environment.authority,
  redirectUri: environment.redirectUri,
  accessTokenRefreshInterval: 3600000, // 1 Hour
};
