<res-layout>
  <res-header>
    <res-logo appName="Encompass Analysis Definition">
      <img res-logo />
    </res-logo>
  </res-header>
      <res-navigation class="dashboard-Options"style="margin:-15px; background-color:#ebedf0 ;">
      <a class="Homepage" routerLink="/analysis-definition" routerLinkActive="active">
        <res-navigation-item>
            <span class="analysis-definitions" [class.active-text]="isRouteActive('/analysis-definition')" >Analysis Definitions</span>
        </res-navigation-item>
    </a>|
    <a class="Homepage" routerLink="/endogenous-crop-assays" routerLinkActive="active">
      <res-navigation-item>
          <span class="analysis-definitions" [class.active-text]="isRouteActive('/endogenous-crop-assays')" >Endogenous Crop Assays</span>
      </res-navigation-item>
    </a>
  <!-- </a>| <a><res-navigation-item>Reporting</res-navigation-item></a> -->

    
    </res-navigation>
    <router-outlet></router-outlet>
  

  <res-footer>
    <section res-footer-application-details>
      <div>UI Version 0.1</div>
      <div>Developed by Team Plasma</div>
      <div>Internal Use Only</div>
    </section>
  </res-footer>
</res-layout>
