import { ApiLabList } from './api-lab';
import { config } from '../../config';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Lab } from '../../interfaces/lab';
import { LabQuery } from '../../interfaces/queries/lab-query';
import { map, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class LabService {
  private uri = config.apiBaseUri + 'labs';

  constructor(private http: HttpClient) {}

  getByQuery = (query?: LabQuery): Observable<Lab[]> => {
    return this.http
      .get<ApiLabList>(this.uri, { params: <any>query })
      .pipe(map((x) => x.items));
  };
}
