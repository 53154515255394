import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { DialogService } from 'primeng/dynamicdialog';
import { ErrorDialogComponent } from '../error-dialog/error-dialog.component';
import { AnalysisDefinitionDetailsRetrieveService } from '../services/analysis-definition-details/analysis-definition-details.service';
import { AnalysisDefinitionEntriesService } from '../services/analysis-definition-entries/analysis-definition-entries.service';
import { AnalysisDefinitionSummaryEntriesService } from '../services/analysis-definition/analysis-definition.service';
import { ASSAY_TYPES_ID_NAME_MAP, TISSUE_PREPARATIONS_ID_NAME_MAP } from '../services/id-mapper/id-mapper';
import {
  AdeOption,
  adeStatusOptions,
  analysisModes,
  assayGroups,
  assayType,
  availableTraits,
  backboneOptions,
  concatSelectedTraits,
  controlTemplateOptions,
  createFormGroupForEntry,
  crops,
  cyclingMethods,
  displayedData,
  dyeOptions,
  dyes,
  extractionMethod,
  instrumentCategoryOptions,
  labs,
  masterMixOptions,
  quencherOptions,
  scoringMethodOptions,
  statusOptions,
  tissuePrep,
  tissuePreparationMap,
  tissueSample,
  tissueSamplingMap,
  tissueTypes,
  trait,
  traits
} from './adDetails.model';

@Component({
  selector: 'app-analysis-definition-details',
  templateUrl: './analysis-definition-details.component.html',
  styleUrls: ['./analysis-definition-details.component.scss'],
  providers: [
    DialogService,
    AnalysisDefinitionEntriesService,
    AnalysisDefinitionSummaryEntriesService,
  ],
})

export class AnalysisDefinitionDetailsComponent implements OnInit {
  isEditOpen = false;
  isCropDisabled = false;
  isTissueTypeDisabled = false;
  isTissuePreparationDisabled = false;
  isTissueSamplingDisabled = false;
  isQuencherDisabled = true;
  isButtonDisplay = false;
  isEndogenousTraitSelect = false;
  selectedCrop = false;
  isADEEditOpen = true;
  showOptions = false;
  newADE = false;
  isADEFormVisible = true;
  isFormUpdating = false;
  showtable = false;
  isADEButtonEnable = false;
  isADEEdit: boolean[];
  ispoolenable: boolean[] = [];

  count = 0;
  selectedAccordianIndex: number = 0;
  selectedItem = 0;
  initialCount = 0;
  show = 0;

  header: string | undefined;
  name = '';
  endogenousTraitFN = '';
  endogenousTraitRON = '';
  endogenousTraitPON = '';
  endogenousTraitGT = '';
  analysisId = '';

  filteredData: availableTraits[] = [];
  SelectedAssayGroupOptions: string[] = [];
  adeForms: FormGroup[] = [];
  availableTraits: availableTraits[] = [];
  endogenousTrait: availableTraits[] = [];
  selectedOptionTraits: availableTraits[] = [];
  assayGroups: assayGroups[] = [];
  statusOptions: statusOptions[] = [];
  dyeOptions: dyeOptions[] = [];
  masterMixOptions: masterMixOptions[] = [];
  quencherOptions: quencherOptions[] = [];
  scoringMethodOptions: scoringMethodOptions[] = [];
  selectedValues: { label: string; value: string }[] = [];
  backboneOptions: backboneOptions[] = [
    { label: 'Yes', value: 'Yes' },
    { label: 'No', value: 'No' },
  ];
  adeStatusOptions: adeStatusOptions[] = [
    { label: 'Active Invalid', value: 'Active Invalid' },
    { label: 'Inactive', value: 'Inactive' },
    { label: 'Active Prod Val', value: 'Active Prod Val' },
    { label: 'Active Valid', value: 'Active Valid' },
  ];
  cropOptions: string[] = [];
  assayTypeOptions: string[] = [];
  labOptions: string[] = [];
  tissueTypeOptions: string[] = [];
  baselineOptions: string[] = [];
  thresholdOptions: string[] = [];
  controlTemplateOptions: string[] = [];
  cyclingMethodsoptions: string[] = [];
  extractionTypeOptions: string[] = [];
  instrumentCategoryOptions: string[] = [];
  tissuePreparationOptions: string[] = [];
  tissueSamplingOptions: string[] = [];
  displayedData: displayedData[] = [];
  accordionSections: {
    isADEEditOpen: boolean;
    header: string;
    content: string;
    form: FormGroup;
    data: any;
  }[] = [];
  userInfo!: {
    displayName: string;
  };

  summaryForm: FormGroup;
  concatSelectedTraits: concatSelectedTraits | undefined;
  SelectedEndogenousTrait: any;
  adeForm: any;
  cropControl: any;
  jsonform: any;
  adeOptions: AdeOption[] = [];

  constructor(
    private fb: FormBuilder,
    private dialogService: DialogService,
    private analysisDefinitionEntriesService: AnalysisDefinitionEntriesService,
    private analysisDefinitionDetailsRetrieveService: AnalysisDefinitionDetailsRetrieveService,
    private route: ActivatedRoute,
    private cdRef: ChangeDetectorRef,
    private router: Router,
    private messageService: MessageService,
  ) {
    this.isADEEdit = [];

    this.summaryForm = this.fb.group({
      adName: [''],
      adStatus: [''],
      assayOligo: [''],
      selectedTraits: [[]],
      createdBy: [''],
      createdOn: [''],
    });

    this.adeForm = this.fb.group({
      adeStatus: ['Active Valid'],
      lastModifiedBy: [''],
      assayGroup: [[null]],
      lastModifiedDate: [''],
      backbone: [{ disabled: true }],
      BaselineStart: [''],
      ControlTemplate: [''],
      Crop: [''],
      MasterMix: [''],
      EndogenousTrait: [''],
      CylinderMethod: [''],
      TissueType: [''],
      InstrumentCategory: [''],
      TissuePreparation: [''],
      ScoringMethod: [''],
      TissueSampling: [''],
      Baseline: [''],
      PooledSamples: [''],
      AssayType: [''],
      BaselineEnd: [''],
      Lab: [''],
      Threshold: [''],
      ExtractionType: [''],
      ThresholdValue: [''],
      AssayMixRecipeId: [''],
    });
  }

  ngOnInit(): void {
    this.userInfo = JSON.parse(sessionStorage.getItem('userInfo') || '{}');
    this.loadAssayTypes();
  }

  loadAssayDetails(data: any) {
    this.jsonform = data;
    this.header = this.jsonform.name;
    this.initialCount = this.jsonform.analysisDefinitionEntry.length;
    this.count = this.jsonform.analysisDefinitionEntry.length;

    this.accordionSections = [];

    const entryArray = this.jsonform.analysisDefinitionEntry.sort((a: any, b: any) => {
      const cropComparison = a.crop.name.localeCompare(b.crop.name);
      if (cropComparison !== 0) {
        return cropComparison;
      }

      const assayTypeComparison = a.assayType.name.localeCompare(b.assayType.name);
      if (assayTypeComparison !== 0) {
        return assayTypeComparison;
      }

      const tissueTypeComparison = a.tissueType.name.localeCompare(b.tissueType.name);
      if (tissueTypeComparison !== 0) {
        return tissueTypeComparison;
      }

      const tissuePreparationComparison = a.tissuePreparations.name.localeCompare(b.tissuePreparations.name);
      if (tissuePreparationComparison !== 0) {
        return tissuePreparationComparison;
      }

      return a.tissueSamplings.numOfSamples - b.tissueSamplings.numOfSamples;
    });

    for (let i = 0; i < entryArray.length; i++) {

      const entry = entryArray[i];
      const entryFormGroup = this.createFormGroupForEntry(entry);
      const backboneValue = entry.backbone ? 'Yes' : 'No';
      const selectedAssayGroupOption: string[] = [];

      this.adeForm = entryFormGroup;

      if (entry.apAssayEnabled) {
        selectedAssayGroupOption.push('AP');
      }
      if (entry.standardAssayEnabled) {
        selectedAssayGroupOption.push('Standard');
      }
      if (entry.eventAssayEnabled) {
        selectedAssayGroupOption.push('Event');
      }

      let selectedADEStatus: string = '';

      if (entry.prodVal) {
        selectedADEStatus = 'Active Prod Val';
      } else if (entry.isValid) {
        selectedADEStatus = 'Active Valid';
      } else if (entry.isActive) {
        selectedADEStatus = 'Active Invalid';
      } else {
        selectedADEStatus = 'Inactive';
      }

      this.SelectedEndogenousTrait = entry.analysisDefinitionEntryTraits.find((trait: { trait: { geneType: string; }; }) => trait.trait.geneType === 'END')?.trait.name;

      try {
        this.adeForm.patchValue({
          adeStatus: selectedADEStatus,
          lastModifiedBy: entry.user.firstName,
          lastModifiedDate: entry.lastModifiedDate,
          backbone: backboneValue,
          assayGroup: selectedAssayGroupOption,
          ControlTemplate: entry.controlTemplate.name,
          Lab: entry.analysisDefinitionEntryLabs[0]?.lab.name,
          BaselineStart: entry.analysisDefinitionEntryBaseline[0]?.start,
          Crop: entry.crop.name,
          MasterMix: entry.masterMixes.description,
          EndogenousTrait: this.SelectedEndogenousTrait,
          CylinderMethod: entry.cyclingMethods.description,
          TissueType: entry.tissueType.name,
          InstrumentCategory: entry.instrumentTypeCategories.name,
          TissuePreparation: entry.tissuePreparations.name,
          ScoringMethod: entry.scoringMethod.name,
          TissueSampling: entry.tissueSamplings.numOfSamples,
          Baseline: entry.analysisDefinitionEntryBaseline[0]?.analysisMode.name,
          PooledSamples: entry.pooledSamples,
          BaselineEnd: entry.analysisDefinitionEntryBaseline[0]?.end,
          AssayType: entry.assayType.name,
          Threshold:
            entry.analysisDefinitionEntryThreshold[0]?.analysisMode.name,
          ExtractionType: entry.extractionMethod.name,
          ThresholdValue: entry.analysisDefinitionEntryThreshold[0]?.value,
        });
      } catch (e) {
        console.log('patch error', e);
      }

      if (this.endogenousTrait.length === 0) {
        this.endogenousTrait.push(this.SelectedEndogenousTrait);
      }

      this.selectedOptionTraits = entry?.analysisDefinitionEntryTraits.sort(
        (a: { trait: trait }, b: { trait: trait }) => {
          if (a.trait.name < b.trait.name) {
            return -1;
          }
          if (a.trait.name > b.trait.name) {
            return 1;
          }
          return 0;
        }
      );

      this.tissueTypeOptions = [];
      this.tissuePreparationOptions = [];
      this.tissueSamplingOptions = [];

      this.tissueTypeOptions.push(entry.tissueType.name);
      this.tissuePreparationOptions.push(entry.tissuePreparations.name);
      this.tissueSamplingOptions.push(entry.tissueSamplings.numOfSamples);

      const accordionSectionsData = {
        endogenousTrait: this.endogenousTrait,
        tissueTypeOptions: this.tissueTypeOptions,
        tissuePreparationOptions: this.tissuePreparationOptions,
        tissueSamplingOptions: this.tissueSamplingOptions,
        selectedOptionTraits: this.selectedOptionTraits,
      };

      this.adeForms.push(this.adeForm);

      this.accordionSections = [
        ...this.accordionSections,
        {
          header: 'Section ' + (i + 1),
          content: entry,
          form: this.adeForms[i],
          isADEEditOpen: false,
          data: accordionSectionsData,
        },
      ];

      this.setEndogeneousOptions(entry.crop.name, i);
      this.cdRef.detectChanges();
    }

    this.concatSelectedTraits = this.jsonform?.analysisDefinitionTraits
      ?.map((trait: traits) => trait.trait.name)
      .join(', ');

    this.summaryForm.patchValue({
      adName: this.jsonform.name,
      adStatus: this.jsonform.analysisDefinitionStatus.status,
      assayOligo: this.jsonform.assayOligoBoxNumber,
      createdBy:
        this.jsonform.user.firstName + ' ' + this.jsonform.user.lastName,
      createdOn: this.jsonform.createdOn,
      selectedTraits: this.concatSelectedTraits,
    });

    this.initForm();
    this.loadAssayGroups();
  }

  initForm(): void {
    this.isEditOpen = false;
    this.isADEEditOpen = false;
  }

  toggleEdit() {
    this.isButtonDisplay = !this.isButtonDisplay;
  }

  editAD() {
    this.show += 1;
    this.isEditOpen = true;
  }

  preventDropdownOpen(event: Event) {
    if (!this.isEditOpen) {
      event.preventDefault();
    }
  }

  saveForm() {
    if (this.summaryForm.valid) {
      this.isEditOpen = false;
    }
  }

  setAnlysisDefinitionData() {
    if (sessionStorage.getItem('analysisDefinitionDetailData')) {
      this.jsonform = JSON.parse(
        sessionStorage.getItem('analysisDefinitionDetailData') || '{}'
      );
      this.loadAssayDetails(this.jsonform);
    } else {
      this.route.queryParams.subscribe((params) => {
        this.analysisId = params['AnalysisId'];
        this.analysisDefinitionDetailsRetrieveService
          .getDetails(this.analysisId)
          .subscribe((data) => {
            sessionStorage.setItem(
              'analysisDefinitionDetailData',
              JSON.stringify(data)
            );
            this.loadAssayDetails(data);
          });
      });
    }
  }

  summarySaveForm() {
    this.summaryForm.disable();
    this.isEditOpen = false;
  }

  toggleTable() {
    this.showtable = !this.showtable;
  }

  AddNewADE() {
    const sectionCount = this.count++;
    const sectionCountIncrement = sectionCount + 1;

    this.newADE = true;

    (this.show += 1),
      this.accordionSections.push({
        header: 'Section ' + sectionCountIncrement,
        content: 'Content ' + 1,
        form: this.createFormGroupForEntry({
          adeStatus: '',
          lastModifiedBy: '',
          assayGroup: [],
          lastModifiedDate: '',
          backbone: '',
          BaselineStart: '',
          ControlTemplate: '',
          Crop: '',
          MasterMix: '',
          EndogenousTrait: '',
          CylinderMethod: '',
          TissueType: '',
          InstrumentCategory: '',
          TissuePreparation: '',
          ScoringMethod: '',
          TissueSampling: '',
          Baseline: '',
          PooledSamples: '',
          AssayType: '',
          BaselineEnd: '',
          Lab: '',
          Threshold: '',
          ExtractionType: '',
          ThresholdValue: '',
          AssayMixRecipeId: ''
        }),
        isADEEditOpen: true,
        data: {},
      });

  }

  loadAssayGroups(): void {
    this.assayGroups = [
      { label: 'AP', value: 'AP' },
      { label: 'Event', value: 'Event' },
      { label: 'Standard', value: 'Standard' },
    ];
  }

  validateForm() {
    if (this.summaryForm.valid) {
      const name = this.summaryForm.get('adName')?.value;
      const isDupicate = this.displayedData.some(
        (item) => item.analysisName === name
      );

      if (isDupicate) {
        const errorMessage = `<span style="color: red; font-weight: bold;">Duplicate Analysis Definition:</span> An AD with the same name already exists and hence cannot be added again. <a href="/analysis-definition" style="color: blue;">Click here</a> to view the existing AD`;
        this.showErrorDialog(errorMessage);
        this.isADEButtonEnable = false;
        this.summaryForm.reset();
        this.summaryForm.get('adStatus')?.setValue(this.statusOptions[0]);
        this.summaryForm.enable();
        return;
      } else {
        this.isADEButtonEnable = true;
        this.summaryForm.disable();
      }
    } else {
      const errorMessage = 'Field "Traits" is required.';
      this.showErrorDialog(errorMessage);
    }
  }

  private showErrorDialog(errorMessage: string) {
    const ref = this.dialogService.open(ErrorDialogComponent, {
      header: 'Error',
      data: {
        errorMessage: errorMessage,
      },
    });
  }

  cancelForm() {
    this.router.navigate(['/analysis-definition']);
  }

  onAssayGroupChange(index: number): void {
    const selectedGroups =
      this.accordionSections[index].form.get('assayGroup')?.value;
    const isAPSelected = selectedGroups.includes('AP');
    if (isAPSelected) {
      this.accordionSections[index].form.get('backbone')?.enable();
    } else {
      this.accordionSections[index].form.get('backbone')?.disable();
    }
  }

  hasError(controlName: string, errorName: string): boolean {
    return this.summaryForm.get(controlName)?.hasError(errorName) || false;
  }

  getAdeOptions(index: number) {
    this.adeOptions = [
      {
        label: 'Edit ADE',
        disabled: false,
        command: () => this.onEditAdeClick(index),
      },
      { label: 'Copy Entry', disabled: true },
      { label: 'Create Master Mix', disabled: true },
      { label: 'View Control Plate', disabled: true },
    ];
  }

  onEditAdeClick(index: number) {
    this.show += 1;
    this.toggleADEEdit(index);

    this.isADEEdit[index] = true;
    this.selectedAccordianIndex = index;

    this.isCropDisabled = true;
    this.isTissueTypeDisabled = true;
    this.isTissuePreparationDisabled = true;
    this.isTissueSamplingDisabled = true;

    this.accordionSections[index].form.get('crop')?.disable();
    this.accordionSections[index].form.get('TissueType')?.disable();
    this.accordionSections[index].form.get('TissuePreparation')?.disable();
    this.accordionSections[index].form.get('TissueSampling')?.disable();
  }

  onDyeDropChanges() {
    this.isQuencherDisabled = false;
  }

  onEndogenousTrait(content: string, index: number) {
    this.isEndogenousTraitSelect = true;
    if (sessionStorage.getItem('analysisDefinitionCache')) {
      const data = JSON.parse(sessionStorage.getItem('analysisDefinitionCache') || '');
      data.combinedTable.map((traits: traits) => {
        traits.traits.map((trait: availableTraits) => {
          if (trait.name === content) {
            this.SelectedEndogenousTrait = trait.name;
            this.endogenousTraitGT = trait.geneType;
            this.endogenousTraitFN = trait.forwardOligoNumber;
            this.endogenousTraitRON = trait.reverseOligoNumber;
            this.endogenousTraitPON = trait.probeOligoNumber;
          }
        });
      });
    }
    else {
      this.analysisDefinitionEntriesService
        .GetProperties()
        .subscribe((data: any) => {
          sessionStorage.setItem('analysisDefinitionCache', JSON.stringify(data));
          data.combinedTable.map((traits: traits) => {
            traits.traits.map((trait: availableTraits) => {
              if (trait.name === content) {
                this.SelectedEndogenousTrait = trait.name;
                this.endogenousTraitGT = trait.geneType;
                this.endogenousTraitFN = trait.forwardOligoNumber;
                this.endogenousTraitRON = trait.reverseOligoNumber;
                this.endogenousTraitPON = trait.probeOligoNumber;
              }
            });
          });
        });
    }

    this.selectedItem = index;
  }


  private Cache: any;
  loadAssayTypes(): void {
    if (sessionStorage.getItem('Cache')) {
      this.Cache = JSON.parse(sessionStorage.getItem('Cache') || '{}');
      this.populateOptions(this.Cache);
      this.setAnlysisDefinitionData();
    }
    else if (this.Cache) {
      this.populateOptions(this.Cache);
      this.setAnlysisDefinitionData();
    } else {
      this.analysisDefinitionEntriesService
        .GetProperties()
        .subscribe((data: any) => {
          this.Cache = data;
          sessionStorage.setItem('Cache', JSON.stringify(data));
          this.populateOptions(data);
          this.setAnlysisDefinitionData();
        });
    }
  }

  populateOptions(data: any): void {
    if (data) {
      this.statusOptions = data.status
        .map((status: statusOptions) => {
          return status.status;
        })
        .sort();

      this.assayTypeOptions = data.assayTypes.map((assayType: assayType) => {
        return assayType.name;
      });

      this.labOptions = data.labs.map((labs: labs) => {
        return labs.name;
      });

      this.tissueTypeOptions = data.tissueType.map((tissueTypes: tissueTypes) => {
        return tissueTypes.name;
      });

      this.baselineOptions = data.analysisModes
        .map((modes: analysisModes) => {
          return modes.name;
        })
        .sort();

      this.thresholdOptions = data.analysisModes
        .map((modes: analysisModes) => {
          return modes.name;
        })
        .sort();

      this.controlTemplateOptions = data.controlTemplates
        .map((controlTemplates: controlTemplateOptions) => {
          return controlTemplates.name;
        })
        .sort();

      this.cyclingMethodsoptions = data.cyclingMethods
        .map((cm: cyclingMethods) => {
          return cm.description;
        })
        .sort();

      this.dyeOptions = data.dyes.map((dy: dyes) => {
        return dy.name;
      });

      this.cropOptions = data.combinedTable.map((crops: crops) => {
        return crops.name;
      });

      data.combinedTable.forEach((item: traits) => {
        item.traits.forEach((trait: any) => {
          this.endogenousTrait.push(trait.name);
        });
      });

      this.endogenousTrait = this.endogenousTrait.flat();

      this.extractionTypeOptions = data.extractionMethod.map((em: extractionMethod) => {
        return em.name;
      });

      this.instrumentCategoryOptions = data.instrumentCategory
        .map((ic: instrumentCategoryOptions) => {
          return ic.name;
        })
        .sort();

      this.masterMixOptions = data.masterMix
        .map((mm: masterMixOptions) => {
          return mm.description;
        })
        .sort();

      this.quencherOptions = data.quencher.map((qu: quencherOptions) => {
        return qu.name;
      });

      this.scoringMethodOptions = data.scoringMethod
        .map((sm: scoringMethodOptions) => {
          return sm.name;
        })
        .sort();

      this.tissuePreparationOptions = data.tissuePrep.map((tp: tissuePrep) => {
        return tp.name;
      });

      this.tissueSamplingOptions = data.tissueSample.map((ts: tissueSample) => {
        return ts.numOfSamples;
      });
    }
  }

  onLabChange(eventValue: string, index: number) {
    this.isCropDisabled = false;

    this.accordionSections[index].form.get('crop')?.enable();
    this.adeForm[index].get('Crop').enable();
    this.adeForm[index].get('TissueType').disable();

    const lab = eventValue;

    if (lab === 'JHN - Johnston') {
      this.cropOptions = [
        'Corn',
        'Soybean',
        'Wheat',
        'Cowpea',
        'Cotton',
        'Millet',
        'Sorghum',
        'Canola',
        'Alfalfa',
        'Sunflower',
        'Arabidopsis',
        'Rice',
        'Sugarcane',
      ];
    } else {
      this.cropOptions = this.Cache.combinedTable.map((crops: crops) => {
        return crops.name;
      });
    }
  }

  setEndogeneousOptions(crop: string, index: number) {
    const selectedCrop = this.Cache.combinedTable.find(
      (crops: crops) => crops.name === crop
    );

    if (selectedCrop) {
      this.endogenousTrait = selectedCrop.traits.map((trait: availableTraits) => {
        this.endogenousTraitFN = trait.forwardOligoNumber;
        this.endogenousTraitRON = trait.reverseOligoNumber;
        this.endogenousTraitPON = trait.probeOligoNumber;
        this.endogenousTraitGT = trait.geneType;
        return trait.name;
      });
    } else {
      this.endogenousTrait = [];
    }

    this.accordionSections[index].data.endogenousTrait = this.endogenousTrait;
  }

  onCropChange(eventValue: string, index: number) {
    this.isTissueTypeDisabled = false;
    this.selectedCrop = true;

    this.accordionSections[index].form.get('TissueType')?.enable();

    const crop = eventValue;
    const lab = this.accordionSections[index].form.get('Lab')?.value;

    this.resetFormValues(index);

    for (let i = 1; i < this.accordionSections.length; i++) {
      const existinCrop = this.accordionSections[i].form.get('Crop')?.value;

      if (existinCrop !== undefined) {
        const selectedExixtingCrop = this.Cache.combinedTable.find(
          (crops: crops) => crops.name === existinCrop
        );
        if (selectedExixtingCrop) {
          this.endogenousTrait = selectedExixtingCrop.traits.map(
            (trait: trait) => trait.name
          );
          this.accordionSections[i].data.endogenousTrait = this.endogenousTrait;
        }
      } else {
        const selectedCrop = this.Cache.combinedTable.find(
          (crops: crops) => crops.name === crop
        );
        if (selectedCrop) {
          this.endogenousTrait = selectedCrop.traits.map((trait: availableTraits) => {
            this.endogenousTraitFN = trait.forwardOligoNumber;
            this.endogenousTraitRON = trait.reverseOligoNumber;
            this.endogenousTraitPON = trait.probeOligoNumber;
            this.endogenousTraitGT = trait.geneType;
            return trait.name;
          });
        } else {
          this.endogenousTrait = [];
        }
        this.accordionSections[index].data.endogenousTrait =
          this.endogenousTrait;
      }
    }

    if (lab === 'JHN - Johnston') {
      const cropOptionsMap: Record<string, any> = {
        Corn: ['Leaf', 'Seed'],
        Soybean: ['Leaf', 'Seed'],
        Wheat: ['Leaf', 'Seed'],
        Cowpea: ['Leaf', 'Seed'],
        Cotton: ['Leaf', 'Seed', 'Cotyledon'],
        Millet: ['Leaf', 'Seed'],
        Sorghum: ['Leaf', 'Seed'],
        Canola: ['Leaf', 'Seed'],
        Alfalfa: ['Leaf'],
        Sunflower: ['Leaf'],
        Arabidopsis: ['Leaf'],
        Rice: ['Leaf'],
        Sugarcane: ['Leaf'],
      };
      this.tissueTypeOptions = cropOptionsMap[crop] || [];
    } else {
      this.tissueTypeOptions = this.Cache.tissueType.map((tissueTypes: tissueTypes) => {
        return tissueTypes.name;
      });
    }

    this.accordionSections[index].data.tissueTypeOptions = this.tissueTypeOptions;
  }

  onThresholdSelect(eventValue: string, index: number) {
    if (eventValue === 'Manual') {
      this.accordionSections[index].form.get('ThresholdValue')?.enable();
    } else {
      this.accordionSections[index].form.get('ThresholdValue')?.disable();
    }
  }

  onBaselineSelect(eventValue: string, index: number) {
    if (eventValue === 'Manual') {
      this.accordionSections[index].form.get('BaselineStart')?.enable();
      this.accordionSections[index].form.get('BaselineEnd')?.enable();
    } else {
      this.accordionSections[index].form.get('BaselineStart')?.disable();
      this.accordionSections[index].form.get('BaselineEnd')?.disable();
    }
  }

  onTissueTypeChange(eventValue: string, index: number) {
    const tissueType = eventValue;
    const crop = this.accordionSections[index].form.get('Crop')?.value;
    const lab = this.accordionSections[index].form.get('Lab')?.value;

    this.resetFormValuesonTissueType(index);

    if (this.accordionSections[index].form.get('TissueType')?.value !== null) {
      this.isTissuePreparationDisabled = false;
      this.accordionSections[index].form.get('TissuePreparation')?.enable();
    } else {
      this.isTissuePreparationDisabled = true;
      this.accordionSections[index].form.get('TissuePreparation')?.disable();
    }

    if (lab === 'JHN - Johnston') {
      const tissuePreparationMap: Record<string, tissuePreparationMap> = {
        Corn: {
          Leaf: ['Punch'],
          Seed: ['Punch', 'Chip'],
        },
        Soybean: {
          Leaf: ['Punch'],
          Seed: ['Whole', 'Flour', 'Chip'],
        },
        Wheat: {
          Leaf: ['Punch'],
          Seed: ['Whole', 'Chip'],
        },
        Cowpea: {
          Leaf: ['Punch'],
        },
        Cotton: {
          Leaf: ['Punch', 'Chip'],
          Seed: ['Whole', 'Chip'],
          Cotyledon: ['Punch'],
        },
        Millet: {
          Leaf: ['Punch'],
        },
        Sorghum: {
          Leaf: ['Punch'],
          Seed: ['Whole'],
        },
        Canola: {
          Leaf: ['Punch'],
          Seed: ['Whole', 'Chip'],
        },
        Alfalfa: {
          Leaf: ['Punch'],
        },
        Sunflower: {
          Leaf: ['Punch'],
        },
        Arabidopsis: {
          Leaf: ['Punch'],
        },
        Rice: {
          Leaf: ['Punch'],
        },
        Sugarcane: {
          Leaf: ['Punch'],
        },
      };
      this.tissuePreparationOptions =
        tissuePreparationMap[crop][tissueType] || [];
    } else {
      this.tissuePreparationOptions = this.Cache.tissuePrep.map((tp: tissuePrep) => {
        return tp.name;
      });
    }

    this.accordionSections[index].data.tissuePreparationOptions =
      this.tissuePreparationOptions;
  }

  onTissuePreparationChange(eventValue: string, index: number) {
    const tissuePreparation = eventValue;
    const crop = this.accordionSections[index].form.get('Crop')?.value;
    const lab = this.accordionSections[index].form.get('Lab')?.value;
    const tissueType =
      this.accordionSections[index].form.get('TissueType')?.value;

    this.isTissuePreparationDisabled = false;
    this.accordionSections[index].form.get('TissueSampling')?.enable();
    this.resetFormValuesonTissuePreparation(index);

    if (lab === 'JHN - Johnston') {
      const tissueSamplingMap: Record<string, tissueSamplingMap> = {
        Corn: {
          Leaf: {
            Punch: ['1', '2', '4'],
          },
          Seed: {
            Punch: ['1'],
            Chip: ['1'],
          },
        },
        Soybean: {
          Leaf: {
            Punch: ['1', '2'],
          },
          Seed: {
            Whole: ['1'],
            Flour: ['20'],
            Chip: ['1'],
          },
        },
        Wheat: {
          Leaf: {
            Punch: ['1', '2'],
          },
          Seed: {
            Whole: ['1'],
            Chip: ['1'],
          },
        },
        Cowpea: {
          Leaf: {
            Punch: ['1'],
          },
        },
        Cotton: {
          Leaf: {
            Punch: ['1', '2', '4'],
            Chip: ['1'],
          },
          Seed: {
            Whole: ['1'],
            Chip: ['1'],
          },
          Cotyledon: {
            Punch: ['1', '2'],
          },
        },
        Millet: {
          Leaf: {
            Punch: ['1'],
          },
        },
        Sorghum: {
          Leaf: {
            Punch: ['1'],
          },
          Seed: {
            Whole: ['1'],
          },
        },
        Canola: {
          Leaf: {
            Punch: ['1', '2'],
          },
          Seed: {
            Whole: ['1'],
            Chip: ['1'],
          },
        },
        Alfalfa: {
          Leaf: {
            Punch: ['1'],
          },
        },
        Sunflower: {
          Leaf: {
            Punch: ['1', '2'],
          },
        },
        Arabidopsis: {
          Leaf: {
            Punch: ['1'],
          },
        },
        Rice: {
          Leaf: {
            Punch: ['1'],
          },
        },
        Sugarcane: {
          Leaf: {
            Punch: ['1'],
          },
        },
      };

      this.tissueSamplingOptions =
        tissueSamplingMap[crop][tissueType][tissuePreparation] || [];
    } else {
      this.tissueSamplingOptions = this.Cache.tissueSample.map((ts: tissueSample) => {
        return ts.numOfSamples;
      });
    }
    this.accordionSections[index].data.tissueSamplingOptions =
      this.tissueSamplingOptions;
  }

  createFormGroupForEntry(entry: createFormGroupForEntry): FormGroup {
    return this.fb.group({
      adeStatus: [{ value: null, disabled: false }],
      lastModifiedBy: [{ value: this.userInfo.displayName, disabled: false }],
      assayGroup: [{ value: null, disabled: false }],
      lastModifiedDate: [{ value: new Date(), disabled: false }],
      backbone: [{ value: null, disabled: false }],
      BaselineStart: [{ value: null, disabled: false }],
      ControlTemplate: [{ value: null, disabled: false }],
      Crop: [{ value: null, disabled: false }],
      MasterMix: [{ value: null, disabled: false }],
      EndogenousTrait: [{ value: null, disabled: false }],
      CylinderMethod: [{ value: null, disabled: false }],
      TissueType: [{ value: null, disabled: false }],
      InstrumentCategory: [{ value: null, disabled: false }],
      TissuePreparation: [{ value: null, disabled: false }],
      ScoringMethod: [{ value: null, disabled: false }],
      TissueSampling: [{ value: null, disabled: false }],
      Baseline: [{ value: null, disabled: false }],
      PooledSamples: [{ value: null, disabled: false }],
      AssayType: [{ value: null, disabled: false }],
      BaselineEnd: [{ value: null, disabled: false }],
      Lab: [{ value: null, disabled: false }],
      Threshold: [{ value: null, disabled: false }],
      ExtractionType: [{ value: null, disabled: false }],
      ThresholdValue: [{ value: null, disabled: false }],
      AssayMixRecipeId: [{ value: null, disabled: false }],
    });
  }

  getFiltered(data: any, index: number): any[] {
    if (data == undefined) {
      const slicedData = this.selectedOptionTraits.filter(
        (item: any) => !item.trait.name.startsWith('ENDOGENOUS')
      );
      return slicedData;
    }

    if (this.isEndogenousTraitSelect && index === this.selectedItem) {
      const slicedData = data.filter(
        (item: any) => !item.trait.name.startsWith('ENDOGENOUS')
      );
      return slicedData;
    }
    return data;
  }

  toggleADEEdit(index: number) {
    if (this.isADEEdit[index] === undefined) {
      this.isADEEdit[index] = false;
    }

    this.isADEEdit[index] = !this.isADEEdit[index];
    this.cropControl = this.accordionSections[index].form.get('crop');
    this.cropControl?.disable();
  }

  onTissueSamplingChange(event: { value: string }, index: number) {
    if (event.value === '4') {
      this.ispoolenable[index] = true;
    } else {
      this.ispoolenable[index] = false;
    }
  }

  resetFormValues(index: number) {
    const form = this.accordionSections[index].form;

    form.get('TissuePreparation')?.setValue(null);
    form.get('TissueSampling')?.setValue(null);
    form.get('TissueType')?.setValue(null);
  }

  resetFormValuesonTissuePreparation(index: number) {
    this.accordionSections[index].form.get('TissueSampling')?.setValue(null);
  }

  resetFormValuesonTissueType(index: number) {
    this.accordionSections[index].form.get('TissuePreparation')?.setValue(null);
    this.accordionSections[index].form.get('TissueSampling')?.setValue(null);
  }

  getMappedId(formField: string, map: Record<string, string>, i: number) {
    return map[this.accordionSections[i].form.get(formField)?.value as keyof typeof map] ?? '';
  }

  private handleSuccess(data: string) {
    this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Data saved successfully' });
    setTimeout(() => {
      this.router.navigate(['/analysis-definition']);
    }, 1000);
  }

  private handleError(error: any) {
    this.messageService.add({ severity: 'error', summary: 'Error', detail: `Data not saved: ${error.error.message}`, life: 1000 });
  }

  saveAdForm(event: any, i: number) {
    if (this.count > this.initialCount) {
      this.saveNewAdForm(i);
      return;
    }

    const id = this.jsonform.analysisDefinitionEntry[i].analysisDefinitionId;
    const requestModel = this.createRequestModel(id, i);

    this.analysisDefinitionEntriesService.UpdateAnalysisDefinitionEntry(requestModel, id).subscribe(
      (data) => {
        this.handleSuccess(data)
      },
      (error) => { this.handleError(error) }
    );
  }

  saveNewAdForm(i: number) {
    const requestModel = this.createRequestModel('00000000-0000-0000-0000-000000000000', i);

    this.analysisDefinitionEntriesService.CreateNewAnalysisDefinitionEntry(requestModel).subscribe(
      (data) => {
        this.handleSuccess(data)
      },
      (error) => { this.handleError(error) }
    );
  }

  createRequestModel(id: string, i: number) {
    return {
      analysisDefinitionId: id,
      analysisDefinitionVersion: 0,
      assayTypeId: this.getMappedId('AssayType', ASSAY_TYPES_ID_NAME_MAP, i),
      cropId: "",
      tissuePreparationId: this.getMappedId('TissuePreparation', TISSUE_PREPARATIONS_ID_NAME_MAP, i),
      tissueSamplingId: "",
      extractionMethodId: "",
      controlTemplateId: "",
      cyclingMethodId: "",
      instrumentCategoryId: "",
      masterMixId: "",
      scoringMethodId: "",
      isActive: false,
      apAssayEnabled: false,
      eventAssayEnabled: false,
      prodVal: false,
      standardAssayEnabled: false,
      backbone: false,
      isValid: false,
      labIds: [],
      tissueTypeId: "",
      pooledSamples: false,
      traits: [{
        forwardOligoConcentration: 0,
        reverseOligoConcentration: 0,
        probeOligoConcentration: 0,
        dyeId: "",
        quencherId: "",
        traitId: ""
      }],
      baselines: [
        {
          baseline: "",
          baselineStart: "",
          baselineEnd: ""
        }
      ],
      thresholds: [
        {
          threshold: '',
          thresholdValue: ''
        }
      ]

    };

  }
}
