
<div *ngIf="!duplicateAnalysisId; else duplicateTemplate">
    <div [innerHTML]="errorMessage"></div>
  </div>
  <ng-template #duplicateTemplate>
    <div>
      <span style="font-weight: bold; color: red">Duplicate Analysis Definition: </span>
      <span>An AD with the same name already exists and hence cannot be added again.</span>
      <a style="color: blue;" (click)="navigateToDetails()">Click here</a>
      <span> to view the existing AD</span>
    </div>
  </ng-template>