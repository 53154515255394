import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../services/auth/auth.service';

@Component({
  selector: 'app-dashboard-options',
  templateUrl: './dashboard-options.component.html',
  styleUrls: ['./dashboard-options.component.scss'],
})
export class DashboardOptionsComponent implements OnInit {
  constructor(
    private authservice: AuthService,
    private router: Router,
  ) {}

  ngOnInit(): void {}
}
