import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-endogenous-crop-assays',
  templateUrl: './endogenous-crop-assays.component.html',
  styleUrls: ['./endogenous-crop-assays.component.scss']
})
export class EndogenousCropAssaysComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
