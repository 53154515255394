<div class="table-of-contents">
  <ng-container>
    <header class="analysis-header">
      Analysis Definition
      <button pButton class="ADEButton" type="button" routerLink="/analysis-definition-creation">
        New Analysis Definition +
      </button>
    </header>
  </ng-container>
  <div class="filters-container">
    <div style="display: flex; flex: 1">
      <button (click)="showfilter = true" *ngIf="!showfilter" style="border: 1px solid rgb(200, 200, 200); width: 5%; margin-top: 1%">
        <div>
          <span>Show Filters</span>
        </div>
      </button>
      <div class="filter-section" *ngIf="showfilter">
        <div class="filter-sidebar">
          <div class="filter-container">
            <div style="text-align: end">
              <button (click)="showFilter()" style="border: none; background: none">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="10" fill="currentColor" class="bi bi-chevron-left" viewBox="0 0 16 16">
                  <path fill-rule="evenodd"
                    d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z" />
                </svg>
                Hide
              </button>
            </div>
            <label> Name </label>
            <input type="text" placeholder="Filter by Name" [(ngModel)]="nameFilter" class="filter-input" />
            <label> Crop </label>
            <input type="text" placeholder="Filter by Crop" [(ngModel)]="cropFilter" class="filter-input" />
            <div class="dropdown-filter">
              <label for="statusFilter">Status:</label>
              <select id="statusFilter" [(ngModel)]="statusFilter" class="filter-select">
                <option value=""></option>
                <option value="Active">Active</option>
                <option value="Inactive">Inactive</option>
              </select>
            </div>
            <div class="ADE-Definition-button-container">
              <button (click)="applyFilters()" class="filter-button">
                Apply
              </button>
              <div class="Right-align">
                <button (click)="resetData()" class="reset-filter-button">
                  Reset
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div style="width: 100%">
        <div *ngIf="tableData != null && tableData.length > 0; else NoData">
          <table class="custom-table">
            <colgroup>
              <ng-template ngFor let-column [ngForOf]="tableColumns">
                <col [style.width.%]="column.width" />
              </ng-template>
            </colgroup>

            <thead>
              <tr>
                <ng-template ngFor let-column [ngForOf]="tableColumns.slice(0, -1)">
                  <th [class]="
                      column.name === pSortableColumn
                        ? 'header-style arrow-' +
                          (pSortDirection === 1 ? 'down' : 'up')
                        : ''
                    " (click)="sortData(column.name)">
                    {{ column.heading }}
                  </th>
                </ng-template>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let row of tableData$ | async">
                <ng-template ngFor let-column [ngForOf]="tableColumns" let-isFirst="first">
                  <td *ngIf="column !== tableColumns[tableColumns.length - 1]">
                    <ng-container *ngIf="isFirst; else noLink">
                      <a [routerLink]="['/analysis-definition-details']" [queryParams]="{
                          AnalysisId:
                            row[tableColumns[tableColumns.length - 1].name]
                        }">
                        {{ row[column.name] }}
                      </a>
                    </ng-container>
                    <ng-template #noLink>
                      {{ row[column.name] }}
                    </ng-template>
                  </td>
                </ng-template>
              </tr>
            </tbody>
          </table>
          <p-paginator [rows]="rowsPerPage" [totalRecords]="totalRecordCount" [rowsPerPageOptions]="[25, 50]"
            (onPageChange)="onPageChange($event)"></p-paginator>
        </div>
        <ng-template #NoData>
          <div class="mesaage-style" *ngIf="noData; else loading">
            No data available
          </div>
        </ng-template>

        <ng-template #loading>
          <div class="loading-container" style="border: 1px solid #d9d9d9; margin: 1%; height: auto">
            <div class="loading-icon"></div>
            <h2 style="margin: 10%; margin-left: 10px; margin-right: 40%">
              Loading data
            </h2>
          </div>
        </ng-template>
      </div>
    </div>
  </div>
</div>