<div class="home">
  <div class="Information-tab">
    <p class="summary">
      Encompass Analysis Definition(EAD) is a key component which will help PAC
      Lab in supporting the characterization of events in plants for the
      transgene and genome editing pipelines. EAD hosts 3 main modules -
      Analysis Definition, Endogenous Crop Assays and Reagent. It will also have
      basic reporting capabilities.
    </p>
    <ul>
      <li class="alignment">
        <i class="fas fa-hand-point-right"></i> Analysis Definition Module: This
        module will help you in creating and managing AD/ ADE. You will also be
        able to view control template associated with the ADE.
      </li>
      <li class="alignment">
        <i class="fas fa-hand-point-right"></i> Reagent Module: This module will
        help you in addition and management of Reagent. Use it to add/ activate/
        generate labels for Reagents.
      </li>
      <li class="alignment">
        <i class="fas fa-hand-point-right"></i> Endogenous Crop Assays Module:
        This module will help you in creation and management of Endogenous Crop
        Assays.
      </li>
      <li class="alignment">
        <i class="fas fa-hand-point-right"></i> Reporting Module: This module
        will help you with your reporting needs. You can view different reports
        and statistics on this page.
      </li>
    </ul>
    <div class="image-container">
      <div class="image" style="margin-left: 19%">
        <img style="height: 100%" src="assets/ug.png" (click)="redirectToUrl()" />
        <div class="overlay">
          <div class="text">User Guide</div>
        </div>
      </div>
      <div class="image" style="margin-left: 10%; margin-right: 10%">
        <img style="height: 100%" src="assets/FAQ'S.png" />
        <div class="overlay">
          <div class="text">FAQ's</div>
        </div>
      </div>
      <div class="image" style="margin-right: 20%">
        <a [href]="supportUrl" target="_blank">
          <img style="height: 100%" src="assets/support.png" />
          <div class="overlay">
            <div class="text">IT Support</div>
          </div>
        </a>
      </div>
    </div>
  </div>
</div>