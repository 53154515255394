export const environment = {
  production: true,
  apiBaseUri: 'https://encompass-iq-api-dev.se.research.corteva.com/api/api/',
  uiBaseUri: 'https://encompassiq-web-dev.encompass.se.research.corteva.com/',
  clientId: '7b46bbf4-0867-4aef-854d-01a0b6be45d5',
  authority: 'https://login.microsoftonline.com/3e20ecb2-9cb0-4df1-ad7b-914e31dcdda4',
  redirectUri: 'https://encompassiq-web-dev.encompass.se.research.corteva.com/auth',
  userguideurl: "https://agcompany.sharepoint.com/:w:/r/sites/Plasma/Shared%20Documents/Encompass%20IQ/Phase%20-%201/07%20-%20Rollout%20Document/01%20-%20User%20Guides%20-%20Encompass%20Request%20-%20IQ%20Jobs/01.03%20-%20Encompass%20Analysis%20Definitions%20-%20User%20Guide.docx?d=w1d00cc65bcea46f1a0f9dc65d98bf58e&csf=1&web=1&e=Mb8bgb",
  supportUrl: "https://jira.research.corteva.com/servicedesk/customer/portal/4",
  accessTokenRefreshInterval: 3600000,
};
