import {
  ChangeDetectorRef,
  Component,
  OnInit
} from '@angular/core';
import { BehaviorSubject, Subscription } from 'rxjs';
import { AnalysisDefinitionSummaryEntriesService } from '../services/analysis-definition/analysis-definition.service';
import { AnalysisDefinition, pageEventClass, tableDataClass } from '../shared/analysisDefinition.model';

@Component({
  selector: 'app-analysis-definition',
  templateUrl: './analysis-definition.component.html',
  styleUrls: ['./analysis-definition.component.scss'],
})

export class AnalysisDefinitionComponent implements OnInit {
  private tableDataSubject = new BehaviorSubject<any[]>([]);
  private analysisDefinitionsData: Subscription | undefined;
  tableData$ = this.tableDataSubject.asObservable();

  pSortableColumn: string = 'name';
  pSortDirection: number = 1;
  tableColumns: { name: string; heading: string; width: string }[] = [
    { name: 'ADName', heading: 'Name', width: '30' },
    { name: 'Crop', heading: 'Crop', width: '40' },
    { name: 'Status', heading: 'Status', width: '25' },
    { name: 'Version', heading: 'Version', width: '5' },
    { name: 'AnalysisId', heading: 'AnalysisId', width: '0' },
  ];

  tableData: tableDataClass[] = [];
  nameFilter = '';
  cropFilter = '';
  statusFilter = '';
  filteredTableData: tableDataClass[] = [];
  rowsPerPage = 25;
  totalRecordCount = 0;
  private displayedData: tableDataClass[] = [];
  noData = false;
  showfilter = true;


  constructor(
    private cdr: ChangeDetectorRef,
    private analysisDefinitionSummaryEntriesService: AnalysisDefinitionSummaryEntriesService,

  ) { }

  ngOnInit(): void {

    const analysisDefinitionData = sessionStorage.getItem('analysisDefinitionData');

    if (analysisDefinitionData) {

      this.tableData = JSON.parse(analysisDefinitionData || '{}');

      this.displayedData = [...this.tableData];

      this.totalRecordCount = this.displayedData.length;

      this.filteredTableData = this.tableData;

      this.sortData('ADName');

      this.onPageChange({ first: 0, rows: this.rowsPerPage });
    } else {
      this.analysisDefinitionsData =
        this.analysisDefinitionSummaryEntriesService
          .getTableData()
          .subscribe((data: AnalysisDefinition[]) => {
            this.tableData = data.map((item) => ({
              ADName: item.analysisName,
              Crop: Array.isArray(item.cropName)
                ? item.cropName.sort().join(', ')
                : 'N/A',
              Version: item.version,
              Status: item.analysisStatus,
              AnalysisId: item.analysisId,
            }));

            this.displayedData = [...this.tableData]

            this.filteredTableData = this.tableData;

            this.sortData('ADName');

            sessionStorage.setItem(
              'analysisDefinitionData',
              JSON.stringify(this.tableData)
            );

            this.totalRecordCount = this.displayedData.length;

            this.onPageChange({ first: 0, rows: this.rowsPerPage });
          });
    }

    const analysisDefinitionDetailsData = sessionStorage.getItem('analysisDefinitionDetailData')
    const cacheData = sessionStorage.getItem('CacheData');

    if (analysisDefinitionDetailsData || cacheData) {
      sessionStorage.removeItem('analysisDefinitionDetailData');
      sessionStorage.removeItem('CacheData');
    }
  }

  applyFilters() {
    const nameFilter = this.nameFilter.toLowerCase();
    const cropFilter = this.cropFilter.toLowerCase();
    const statusFilter = this.statusFilter.toLowerCase();

    this.tableData = [...this.displayedData];

    const filteredData = this.tableData.filter((row) =>
      row['ADName'].toLowerCase().includes(nameFilter) &&
      row['Crop'].toLowerCase().includes(cropFilter) &&
      (statusFilter === '' || row['Status'].toLowerCase() === statusFilter)
    );

    this.totalRecordCount = filteredData.length;

    if (filteredData.length === 0) {
      this.noData = true;
    }

    this.filteredTableData = filteredData;
    this.tableData = filteredData;
    this.rowsPerPage = 25;

    this.tableDataSubject.next(filteredData);

    this.onPageChange({ first: 0, rows: this.rowsPerPage });

  }

  resetData() {
    this.filteredTableData = [...this.displayedData];
    this.tableData = [...this.displayedData];
    this.tableDataSubject.next(this.tableData);
    this.nameFilter = '';
    this.cropFilter = '';
    this.statusFilter = '';
    this.pSortableColumn = 'name';
    this.pSortDirection = 1;
    this.applyFilters();
    this.sortData('ADName');
  }

  sortData(columnName: string) {
    if (this.pSortableColumn === columnName) {
      this.pSortDirection = -this.pSortDirection;
    } else {
      this.pSortableColumn = columnName;
      this.pSortDirection = 1;
    }

    this.filteredTableData?.sort((a, b) => {
      const aValue = a[this.pSortableColumn];
      const bValue = b[this.pSortableColumn];

      if (aValue < bValue) {
        return -this.pSortDirection;
      }

      if (aValue > bValue) {
        return this.pSortDirection;
      }

      return 0;
    });
  }

  onPageChange(event: pageEventClass) {
    const startIndex = event.first;
    let endIndex;

    if (event.rows > 0) {
      endIndex = startIndex + event.rows;
      this.rowsPerPage = event.rows;
    } else {
      endIndex = startIndex + this.rowsPerPage;
    }

    this.filteredTableData = this.tableData.slice(startIndex, endIndex);
    this.tableDataSubject.next(this.filteredTableData);
  }

  showFilter() {
    this.showfilter = !this.showfilter;
  }

  ngOnDestroy() {
    this.analysisDefinitionsData?.unsubscribe();
  }
}
