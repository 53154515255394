import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ADEntries } from 'src/app/interfaces/ad-entries';
import { config } from '../../config';

@Injectable({
  providedIn: 'root',
})

export class AnalysisDefinitionEntriesService {
  private propertiesUri = config.apiBaseUri + 'analysisDefinitionEntries/properties';
  private analysisDefinitionsUri = config.apiBaseUri + 'analysisDefinitions';
  private entriesUri = config.apiBaseUri + 'analysisDefinitionEntries/';
  private data: any[] = [];

  constructor(private http: HttpClient) { }

  GetProperties = (): Observable<ADEntries> => {
    return this.http.get<ADEntries>(this.propertiesUri);
  };

  SaveAnalysisDefinitionEntry = (ade: any): Observable<any> => {
    const url = `${this.analysisDefinitionsUri}`;
    return this.http.post<any>(url, ade);
  }

  //Update The existing Analysis Definition Entry
  UpdateAnalysisDefinitionEntry = (ade: any, id: string): Observable<any> => {
    const uri = `${this.entriesUri}${id}`;
    return this.http.patch<any>(uri, ade);
  }

  //Create New Analysis Definition Entry for existing AD
  CreateNewAnalysisDefinitionEntry = (ade: any): Observable<any> => {
    const uri = `${this.entriesUri}`;
    return this.http.post<any>(uri, ade);
  }

  isDupicateName(name: string): boolean {
    return this.data.some((item) => item.name === name);
  }
}
