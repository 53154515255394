import { Component, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators
} from '@angular/forms';
import { Router } from '@angular/router';
import { MenuItem, MessageService } from 'primeng/api';
import { DialogService } from 'primeng/dynamicdialog';
import { ErrorDialogComponent } from '../error-dialog/error-dialog.component';
import { ExistingADErrorDialogComponent } from '../existing-ad-error-dialog/existing-ad-error-dialog.component';
import { AnalysisDefinitionEntriesService } from '../services/analysis-definition-entries/analysis-definition-entries.service';
import { AnalysisDefinitionSummaryEntriesService } from '../services/analysis-definition/analysis-definition.service';
import { ANALYSIS_MODES_ID_NAME_MAP, ASSAY_TYPES_ID_NAME_MAP, CONTROL_TEMPLATES_ID_NAME_MAP, CROP_TYPES_ID_NAME_MAP, EXTRACTION_METHODS_ID_NAME_MAP, TISSUE_PREPARATIONS_ID_NAME_MAP, TISSUE_SAMPLINGS_ID_NAME_MAP, TISSUE_TYPES_ID_NAME_MAP } from '../services/id-mapper/id-mapper';
import { TraitsRetriveService } from '../services/traits/traits';
import {
  AnalysisDefinitionEntryParameter,
  Traits,
  analysisModes,
  assayTypes,
  controlTemplates,
  crops,
  cropsType,
  cyclingMethods,
  dyeType,
  extractionMethod,
  instrumentCategory,
  masterMix,
  quencherType,
  saveTraitsModel,
  scoringMethod,
  selectedOptionTraits,
  tableData,
  tissuePrep,
  tissuePreparationMap,
  tissueSample,
  tissueSamplingMap,
  tissueTypes,
  traitsInfo,
  traitsModel
} from './adCreation.model';

@Component({
  selector: 'app-analysis-definition-creation',
  templateUrl: './analysis-definition-creation.component.html',
  styleUrls: ['./analysis-definition-creation.component.scss'],
  providers: [
    DialogService,
    AnalysisDefinitionEntriesService,
    AnalysisDefinitionSummaryEntriesService,
  ],
})

export class AnalysisDefinitionCreationComponent implements OnInit {
  summaryForm: FormGroup;
  adeForm: FormGroup;

  isEditOpen = false;
  isQuencherDisabled = true;
  isButtonDisplay = false;
  isEndogenousTraitSelect = false;
  selectedCrop = false;
  isADEEditOpen = true;
  showOptions = false;
  isADEFormVisible = false;
  isFormUpdating = false;
  isADEButtonEnable = false;
  isLoading = false;

  count = 0;

  SelectedEndogenousTrait: string = '';
  name = '';
  endogenousTraitFN = '';
  endogenousTraitRON = '';
  endogenousTraitPON = '';
  endogenousTraitGT = '';
  endogenousTraitVersion = 0;
  statusOptionsID = '';
  labIds = '';

  filteredData: { id: string; name: string }[] = [];
  SelectedAssayGroupOptions: string[] = [];
  availableTraits: { id: string, name: string }[] = [];
  endogenousTrait: string[] = [];
  statusOptions: { id: string; name: string; status: string }[] = [];
  dyeOptions: string[] = [];
  cropOptions: string[] = [];
  assayTypeOptions: string[] = [];
  labOptions: string[] = [];
  tissueTypeOptions: string[] = [];
  baselineOptions: string[] = [];
  thresholdOptions: string[] = [];
  controlTemplateOptions: controlTemplates[] = [];
  cyclingMethodsoptions: string[] = [];
  extractionTypeOptions: string[] = [];
  instrumentCategoryOptions: string[] = [];
  quencherOptions: string[] = [];
  scoringMethodOptions: string[] = [];
  tissuePreparationOptions: string[] = [];
  tissueSamplingOptions: string[] = [];

  selectedValues: { label: string; value: string }[] = [];
  tissueSampling: { label: string; value: string }[] = [
    { label: '1', value: '1' },
    { label: '2', value: '2' },
    { label: '3', value: '3' },
    { label: '4', value: '4' },
  ];
  backboneOptions = [
    { label: 'Yes', value: 'Yes' },
    { label: 'No', value: 'No' },
  ];
  adeStatusOptions = [
    { label: 'Active Invalid', value: 'Active Invalid' },
    { label: 'Inactive', value: 'Inactive' },
    { label: 'Active Prod Val', value: 'Active Prod Val' },
    { label: 'Active Valid', value: 'Active Valid' },
  ];
  assayGroups: { label: string; value: string; }[] = [];
  tableData: tableData[] = [];
  displayedData: tableData[] = [];

  userInfo!: {
    displayName: string;
  };

  adeOptions: MenuItem[];
  masterMixOptions = [];
  selectedOptionTraits: selectedOptionTraits[] = [];
  traits: { traitId: string; forwardOligoConcentration: string; reverseOligoConcentration: string; probeOligoConcentration: string; dyeId: string; quencherId: string; traitVersion: number }[] = [];
  endogenousTraitID = '';

  constructor(
    private fb: FormBuilder,
    private dialogService: DialogService,
    private analysisDefinitionEntriesService: AnalysisDefinitionEntriesService,
    private analysisDefinitionSummaryEntriesService: AnalysisDefinitionSummaryEntriesService,
    private traitsRetriveService: TraitsRetriveService,
    private messageService: MessageService,
    private router: Router
  ) {
    this.summaryForm = this.fb.group({
      adName: [''],
      adStatus: [''],
      assayOligo: [''],
      selectedTraits: [[]],
      createdBy: [''],
      createdOn: [''],
    });

    this.adeForm = this.fb.group({
      adeStatus: ['Active Invalid'],
      lastModifiedBy: [''],
      assayGroup: [[null]],
      lastModifiedDate: [''],
      backbone: [{ disabled: true }],
      BaselineStart: [''],
      ControlTemplate: [''],
      Crop: ['', Validators.required],
      MasterMix: ['', Validators.required],
      EndogenousTrait: ['', Validators.required],
      CylinderMethod: [''],
      TissueType: ['', Validators.required],
      InstrumentCategory: ['', Validators.required],
      TissuePreparation: ['', Validators.required],
      ScoringMethod: ['', Validators.required],
      TissueSampling: [''],
      Baseline: [''],
      PooledSamples: [{ value: false, disabled: true }],
      AssayType: ['', Validators.required],
      BaselineEnd: ['', Validators.required],
      Lab: ['', Validators.required],
      Threshold: [''],
      ExtractionType: [''],
      ThresholdValue: [''],
      AssayMixRecipeId: [''],
      endogeniousDye: [''],
      endogeniousQuencher: [''],
      endogeniousProbe: [''],
      endogeniousForward: [''],
      endogeniousReverse: [''],
    });

    this.adeOptions = [
      {
        label: 'Edit ADE',
        disabled: false,
        command: () => this.onEditAdeClick(),
      },
      { label: 'Copy Entry', disabled: true },
      { label: 'Create Master Mix', disabled: true },
      { label: 'View Control Plate', disabled: true },
    ];
  }

  ngOnInit(): void {
    this.initForm();
    this.loadAssayGroups();
    this.loadAssayTypes();
    this.fetchTraitsNames();

    this.adeForm.get('backbone')?.disable();

    this.statusOptionsID = this.statusOptions[0]?.id;

    if (sessionStorage.getItem('tableData')) {
      this.displayedData = JSON.parse(
        sessionStorage.getItem('tableData') || '{}'
      );
    } else {
      this.analysisDefinitionSummaryEntriesService
        .getTableData()
        .subscribe((data) => {
          this.tableData = data;
          if (this.tableData.length > 0) {
            sessionStorage.setItem('tableData', JSON.stringify(this.tableData));
          }
          this.displayedData = [...this.tableData];
        });
    }

    this.adeForm.get('Baseline')?.valueChanges.subscribe((status) => {
      const baseLineStart = this.adeForm.get('BaselineStart');
      const balseLineEnd = this.adeForm.get('BaselineEnd');

      if (status === 'Automatic') {
        baseLineStart?.disable();
        balseLineEnd?.disable();
        baseLineStart?.setValue(null);
        balseLineEnd?.setValue(null);
      } else {
        baseLineStart?.enable();
        balseLineEnd?.enable();
      }
    });

    this.adeForm.get('Threshold')?.valueChanges.subscribe((status) => {
      const thresholdValue = this.adeForm.get('ThresholdValue');

      if (status === 'Automatic') {
        thresholdValue?.disable();
        thresholdValue?.setValue(null);
      } else {
        thresholdValue?.enable();
      }
    });

    this.adeForm.get('TissueSampling')?.valueChanges.subscribe((status) => {
      const poolSamples = this.adeForm.get('PooledSamples');

      if (status === '4') {
        poolSamples?.enable();
      } else {
        poolSamples?.disable();
        poolSamples?.setValue(false);
      }
    });

    this.userInfo = JSON.parse(sessionStorage.getItem('userInfo') || '{}');
    if (this.userInfo && this.userInfo.displayName) {
      this.summaryForm.get('createdBy')?.setValue(this.userInfo.displayName);
    }
  }

  initForm(): void {
    this.isEditOpen = false;

    this.summaryForm = this.fb.group({
      adName: [''],
      adStatus: ['', Validators.required],
      assayOligo: [''],
      selectedTraits: [[], Validators.required],
      createdBy: [''],
      createdOn: [''],
    });

    this.adeForm.valueChanges.subscribe(() => {
      if (this.adeForm.get('lastModifiedDate')?.value === null || this.adeForm.get('lastModifiedDate')?.value === '') {
        this.adeForm.get('lastModifiedDate')?.setValue(new Date(), { emitEvent: false });
      }
      if (this.adeForm.get('lastModifiedBy')?.value === null || this.adeForm.get('lastModifiedBy')?.value === '') {
        this.adeForm.get('lastModifiedBy')?.setValue(this.userInfo.displayName, { emitEvent: false });
      }

    });
    this.summaryForm.valueChanges.subscribe(() => {
      if (!this.isFormUpdating) {
        this.isFormUpdating = true;
        this.summaryForm.patchValue({
          createdOn: new Date(),
        });
        this.isFormUpdating = true;
      }
    });
  }

  loadAssayGroups() {
    this.assayGroups.push(
      { label: 'AP', value: 'AP' },
      { label: 'Event', value: 'Event' },
      { label: 'Standard', value: 'Standard' }
    );
  }

  toggleEdit() {
    this.isButtonDisplay = !this.isButtonDisplay;
  }

  editAD() {
    this.summaryForm.enable();
    this.isEditOpen = true;
    this.isADEButtonEnable = false;
  }

  preventDropdownOpen(event: Event) {
    if (!this.isEditOpen) {
      event.preventDefault();
    }
  }

  validateForm() {
    if (this.summaryForm.valid) {
      const name = this.summaryForm.get('adName')?.value;
      const duplicateItem = this.displayedData.find((item) => item.analysisName === name);

      if (duplicateItem) {
        const duplicateAnalysisId = duplicateItem.analysisId;
        this.showExistingError(
          'Duplicate Analysis Definition: An AD with the same name already exists and hence cannot be added again.',
          duplicateAnalysisId
        );
      } else {
        this.isADEButtonEnable = true;
        this.summaryForm.disable();
      }
    } else {
      const missingFields = []

      //TO-DO: If needed we need to add more fields to the missingFields array
      if (this.summaryForm.get('selectedTraits')?.value.length === 0) {
        missingFields.push('Traits');
      }

      const errorMessage = `Field : <strong>${missingFields}</strong> is required`;
      this.showErrorDialog(errorMessage);
    }
  }

  private showExistingError(errorMessage: string, duplicateAnalysisId: number) {
    const ref = this.dialogService.open(ExistingADErrorDialogComponent, {
      header: 'Error',
      data: {
        errorMessage: errorMessage,
        duplicateAnalysisId: duplicateAnalysisId,
      },
    });
  }

  private showErrorDialog(errorMessage: string) {
    const ref = this.dialogService.open(ErrorDialogComponent, {
      header: 'Error',
      data: {
        errorMessage: errorMessage,
      },
    });
  }

  cancelForm() {
    this.summaryForm.reset();
    this.summaryForm.enable();

    this.isEditOpen = false;
    this.isADEButtonEnable = false;
    this.isADEFormVisible = false;

    if (!this.isADEFormVisible) {
      this.adeForm.reset();
    }
  }

  formADEVisibility() {
    this.count = +1;

    this.selectedOptionTraits.forEach((trait, index) => {
      this.adeForm.addControl('forwardOligoConcentration' + index, new FormControl());
      this.adeForm.addControl('probeOligoConcentration' + index, new FormControl());
      this.adeForm.addControl('reverseOligoConcentration' + index, new FormControl());
      this.adeForm.addControl('quencher' + index, new FormControl());
      this.adeForm.addControl('dye' + index, new FormControl());
      this.traitsRetriveService.GetTraitInfo(trait.id).subscribe((trait: traitsInfo) => {
        this.selectedOptionTraits[index].forwardOligoNumber = trait.forwardOligoNumber;
        this.selectedOptionTraits[index].reverseOligoNumber = trait.reverseOligoNumber;
        this.selectedOptionTraits[index].probeOligoNumber = trait.probeOligoNumber;
        this.selectedOptionTraits[index].geneType = trait.geneType;
        this.selectedOptionTraits[index].version = trait.version;

      });
    });

    this.isADEFormVisible = !this.isADEFormVisible;

    if (!this.isADEFormVisible) {
      this.adeForm.reset();
    }
  }

  onAssayGroupChange() {
    const selectedGroups = this.adeForm.get('assayGroup')?.value;
    const isAPSelected = selectedGroups.includes('AP');

    if (isAPSelected) {
      this.adeForm.get('backbone')?.enable();
    } else {
      this.adeForm.get('backbone')?.disable();
      this.adeForm.get('backbone')?.setValue(null);
    }
  }

  filterTraits(eventQuery: string) {
    const query = eventQuery.toLowerCase();
    this.filteredData = this.availableTraits?.filter((trait) =>
      trait.name.toLowerCase().includes(query)
    );

    this.selectedOptionTraits = this.selectedOptionTraits?.filter(
      (item) => item !== undefined && item !== null
    );
    this.name = this.selectedOptionTraits.join('_');
  }

  hasError(controlName: string, errorName: string): boolean {
    return this.summaryForm.get(controlName)?.hasError(errorName) || false;
  }

  onEditAdeClick() {
    this.adeOptions.forEach((option) => {
      if (option.label !== 'Edit ADE') {
        this.isADEEditOpen = true;
      }
    });
  }

  onDyeDropChanges(index: number) {
    this.adeForm.get('quencher' + index)?.enable();
  }

  onEndogenousTrait() {
    this.isEndogenousTraitSelect = true;
  }



  updateName() {
    this.selectedOptionTraits = this.selectedOptionTraits?.filter(
      (item) => item !== undefined && item !== null
    );

    if (this.selectedOptionTraits?.length > 0) {
      const name = this.selectedOptionTraits?.map((trait: selectedOptionTraits) => {
        return trait.name;
      });

      this.selectedOptionTraits = this.selectedOptionTraits?.sort((a, b) => a.name.localeCompare(b.name));

      name.sort();
      this.name = name.join('_');
    } else {
      this.name = '';
    }
  }

  limitTraitsSelections() {
    if (this.selectedOptionTraits.length > 5) {
      const errorMessage = 'Max Limit exceeded';

      this.showErrorDialog(errorMessage);
      this.selectedOptionTraits.pop();
    }
  }

  private catcheData: any = {};

  loadAssayTypes() {
    if (sessionStorage.getItem('CatcheData')) {
      this.catcheData = JSON.parse(
        sessionStorage.getItem('CatcheData') || '{}'
      );
      this.populateOptions(this.catcheData);
    } else {
      this.analysisDefinitionEntriesService
        .GetProperties()
        .subscribe((data: any) => {
          sessionStorage.setItem('CatcheData', JSON.stringify(data));
          this.catcheData = data;
          this.populateOptions(data);
        });
    }
  }

  populateOptions(data: any) {
    if (data) {
      this.statusOptions = data.status;

      this.assayTypeOptions = data.assayTypes.map((assayType: assayTypes) => {
        return assayType.name;
      });

      this.labOptions = data.labs;

      this.tissueTypeOptions = data.tissueType.map((tissueTypes: tissueTypes) => {
        return tissueTypes.name;
      });

      this.baselineOptions = data.analysisModes
        .map((modes: analysisModes) => {
          return modes.name;
        })
        .sort();

      this.thresholdOptions = data.analysisModes
        .map((modes: analysisModes) => {
          return modes.name;
        })
        .sort();

      this.controlTemplateOptions = data.controlTemplates
        ?.map((controlTemplate: controlTemplates) => {
          return controlTemplate.name
        })
        .sort();

      this.cyclingMethodsoptions = data.cyclingMethods
        .map((cm: cyclingMethods) => {
          return { description: cm.description, id: cm.id };
        })
        .sort((a: { description: string; }, b: { description: string; }) => a.description.localeCompare(b.description));

      this.dyeOptions = data.dyes.map((dy: dyeType) => {
        return dy.name;
      });

      this.cropOptions = data.combinedTable.map((crops: cropsType) => {
        return crops.name;
      });

      data.combinedTable.forEach((et: any) => {
        et.traits.forEach((trait: Traits) => {
          this.endogenousTrait.push(trait.name);
        });
      });

      this.extractionTypeOptions = data.extractionMethod.map((em: extractionMethod) => {
        return em.name;
      });

      this.instrumentCategoryOptions = data.instrumentCategory
        .map((ic: instrumentCategory) => {
          return { name: ic.name, id: ic.id };
        })
        .sort();

      this.masterMixOptions = data.masterMix
        .map((mm: masterMix) => {
          return { description: mm.description, id: mm.id };
        })
        .sort((a: { description: string; }, b: { description: string; }) => a.description.localeCompare(b.description));

      this.quencherOptions = data.quencher.map((qu: quencherType) => {
        return qu.name;
      });

      this.scoringMethodOptions = data.scoringMethod
        .map((sm: scoringMethod) => {
          return { name: sm.name, id: sm.id };
        })
        .sort((a: { name: string; }, b: { name: string; }) => a.name.localeCompare(b.name));

      this.tissuePreparationOptions = data.tissuePrep.map((tp: tissuePrep) => {
        return tp.name;
      });

      this.tissueSamplingOptions = data.tissueSample.map((ts: tissueSample) => {
        return ts.numOfSamples;
      });
    }
  }

  onLabChange(event: any) {
    const lab = event.name;
    this.labIds = event.id;
    this.adeForm.get('Crop')?.setValue(null);
    this.resetFormValues();

    if (lab === 'JHN - Johnston') {
      this.cropOptions = [
        'Corn',
        'Soybean',
        'Wheat',
        'Cowpea',
        'Cotton',
        'Millet',
        'Sorghum',
        'Canola',
        'Alfalfa',
        'Sunflower',
        'Arabidopsis',
        'Rice',
        'Sugarcane',
      ];
    } else {
      this.adeForm.get('PooledSamples')?.enable();
      this.cropOptions = this.catcheData.combinedTable.map((crops: crops) => {
        return crops.name;
      });
    }
  }

  onCropChange(event: string) {
    this.selectedCrop = true;
    const crop = event;
    const lab = this.adeForm.get('Lab')?.value.name;

    this.resetFormValues();

    const adSelectedCrop = this.catcheData.combinedTable.find(
      (crops: crops) => crops.name === crop
    );

    if (adSelectedCrop) {
      this.endogenousTrait = adSelectedCrop.traits.map(
        (trait: Traits) => trait.name
      );

      adSelectedCrop.traits.map((trait: selectedOptionTraits) => {
        this.endogenousTraitFN = trait.forwardOligoNumber;
        this.endogenousTraitRON = trait.reverseOligoNumber;
        this.endogenousTraitPON = trait.probeOligoNumber;
        this.endogenousTraitGT = trait.geneType;
        this.endogenousTraitID = trait.id;
        this.endogenousTraitVersion = trait.version;
      });
    }

    if (lab === 'JHN - Johnston') {
      const cropOptionsMap: Record<string, any> = {
        Corn: ['Leaf', 'Seed'],
        Soybean: ['Leaf', 'Seed'],
        Wheat: ['Leaf', 'Seed'],
        Cowpea: ['Leaf', 'Seed'],
        Cotton: ['Leaf', 'Seed', 'Cotyledon'],
        Millet: ['Leaf', 'Seed'],
        Sorghum: ['Leaf', 'Seed'],
        Canola: ['Leaf', 'Seed'],
        Alfalfa: ['Leaf'],
        Sunflower: ['Leaf'],
        Arabidopsis: ['Leaf'],
        Rice: ['Leaf'],
        Sugarcane: ['Leaf'],
      };
      this.tissueTypeOptions = cropOptionsMap[crop] || [];
    } else {
      this.tissueTypeOptions = this.catcheData.tissueType.map(
        (tissueTypes: tissueTypes) => {
          return tissueTypes.name;
        }
      );
    }
  }

  onTissueTypeChange(event: string) {
    const tissueType = event;
    const crop = this.adeForm.get('Crop')?.value;
    const lab = this.adeForm.get('Lab')?.value.name;
    this.adeForm.get('TissueType')?.setValue(tissueType);

    if (lab === 'JHN - Johnston') {
      const tissuePreparationMap: Record<string, tissuePreparationMap> = {
        Corn: {
          Leaf: ['Punch'],
          Seed: ['Punch', 'Chip'],
        },
        Soybean: {
          Leaf: ['Punch'],
          Seed: ['Whole', 'Flour', 'Chip'],
        },
        Wheat: {
          Leaf: ['Punch'],
          Seed: ['Whole', 'Chip'],
        },
        Cowpea: {
          Leaf: ['Punch'],
        },
        Cotton: {
          Leaf: ['Punch', 'Chip'],
          Seed: ['Whole', 'Chip'],
          Cotyledon: ['Punch'],
        },
        Millet: {
          Leaf: ['Punch'],
        },
        Sorghum: {
          Leaf: ['Punch'],
          Seed: ['Whole'],
        },
        Canola: {
          Leaf: ['Punch'],
          Seed: ['Whole', 'Chip'],
        },
        Alfalfa: {
          Leaf: ['Punch'],
        },
        Sunflower: {
          Leaf: ['Punch'],
        },
        Arabidopsis: {
          Leaf: ['Punch'],
        },
        Rice: {
          Leaf: ['Punch'],
        },
        Sugarcane: {
          Leaf: ['Punch'],
        },
      };
      this.tissuePreparationOptions =
        tissuePreparationMap[crop][tissueType] || [];
    } else {
      this.tissuePreparationOptions = this.catcheData.tissuePrep.map(
        (tp: tissuePrep) => {
          return tp.name;
        }
      );
    }
  }

  onTissuePreparationChange(event: string) {
    const tissuePreparation = event;
    const crop = this.adeForm.get('Crop')?.value;
    const lab = this.adeForm.get('Lab')?.value.name;
    const tissueType = this.adeForm.get('TissueType')?.value;
    this.adeForm.get('TissueType')?.setValue(tissueType);
    this.adeForm.get('TissuePreparation')?.setValue(tissuePreparation);

    if (lab === 'JHN - Johnston') {
      const tissueSamplingMap: Record<string, tissueSamplingMap> = {
        Corn: {
          Leaf: {
            Punch: ['1', '2', '4'],
          },
          Seed: {
            Punch: ['1'],
            Chip: ['1'],
          },
        },

        Soybean: {
          Leaf: {
            Punch: ['1', '2'],
          },
          Seed: {
            Whole: ['1'],
            Flour: ['20'],
            Chip: ['1'],
          },
        },

        Wheat: {
          Leaf: {
            Punch: ['1', '2'],
          },
          Seed: {
            Whole: ['1'],
            Chip: ['1'],
          },
        },

        Cowpea: {
          Leaf: {
            Punch: ['1'],
          },
        },

        Cotton: {
          Leaf: {
            Punch: ['1', '2', '4'],
            Chip: ['1'],
          },
          Seed: {
            Whole: ['1'],
            Chip: ['1'],
          },
          Cotyledon: {
            Punch: ['1', '2'],
          },
        },

        Millet: {
          Leaf: {
            Punch: ['1'],
          },
        },
        Sorghum: {
          Leaf: {
            Punch: ['1'],
          },
          Seed: {
            Whole: ['1'],
          },
        },

        Canola: {
          Leaf: {
            Punch: ['1', '2'],
          },
          Seed: {
            Whole: ['1'],
            Chip: ['1'],
          },
        },

        Alfalfa: {
          Leaf: {
            Punch: ['1'],
          },
        },

        Sunflower: {
          Leaf: {
            Punch: ['1', '2'],
          },
        },

        Arabidopsis: {
          Leaf: {
            Punch: ['1'],
          },
        },

        Rice: {
          Leaf: {
            Punch: ['1'],
          },
        },

        Sugarcane: {
          Leaf: {
            Punch: ['1'],
          },
        },
      };

      this.tissueSamplingOptions =
        tissueSamplingMap[crop][tissueType][tissuePreparation] || [];
    } else {
      this.tissueSamplingOptions = this.catcheData.tissueSample.map(
        (ts: tissueSample) => {
          return ts.numOfSamples;
        }
      );
    }
  }

  resetFormValues() {
    this.adeForm.get('TissuePreparation')?.setValue(null);
    this.adeForm.get('TissueSampling')?.setValue(null);
    this.adeForm.get('TissueType')?.setValue(null);
  }

  fetchTraitsNames() {
    if (sessionStorage.getItem('validateDuplicate') == 'true') {
      this.refreshData();
      sessionStorage.removeItem('validateDuplicate');
    }

    if (sessionStorage.getItem('AvailableTraits')) {
      this.availableTraits = JSON.parse(
        sessionStorage.getItem('AvailableTraits') || '{}'
      );
    } else {
      this.traitsRetriveService.GetTraitsList().subscribe((TraitsData: traitsModel[]) => {
        this.availableTraits = TraitsData.map((trait: traitsModel) => {
          return { id: trait.id, name: trait.name };
        });

        sessionStorage.setItem(
          'AvailableTraits',
          JSON.stringify(this.availableTraits)
        );
      });
    }
  }

  refreshData() {
    this.traitsRetriveService.GetTraitsList().subscribe((TraitsData: traitsModel[]) => {
      this.availableTraits = TraitsData.map((trait: traitsModel) => {
        return { id: trait.id, name: trait.name };
      });
    });
  }

  onStatusChange(id: string) {
    this.statusOptionsID = id;
  }

  getMappedId(formField: string, map: Record<string, string>) {
    return map[this.adeForm.get(formField)?.value as keyof typeof map] ?? '';
  }

  getFormValue(formControlName: string) {
    return this.adeForm.get(formControlName)?.value;
  }

  mapADEStatus(status: string) {
    switch (status) {
      case 'Active Invalid':
        return { isActive: 1, isValid: 0, prodVal: 0 };
      case 'Active Prod Val':
        return { isActive: 1, isValid: 1, prodVal: 1 };
      case 'Active Val':
        return { isActive: 1, isValid: 1, prodVal: 0 };
      default:
        return { isActive: 0, isValid: 0, prodVal: 0 };
    }
  }

  isAssayGroupEnabled(assayGroup: string[], assayType: string) {
    return assayGroup.includes(assayType);
  }

  createTraits() {
    return this.selectedOptionTraits.map((trait: selectedOptionTraits, index) => {
      return {
        traitId: trait.id,
        traitVersion: trait.version,
        forwardOligoConcentration: this.adeForm.get('forwardOligoConcentration' + index)?.value,
        reverseOligoConcentration: this.adeForm.get('reverseOligoConcentration' + index)?.value,
        probeOligoConcentration: this.adeForm.get('probeOligoConcentration' + index)?.value,
        dyeId: "cd1a58c2-3aa3-e711-8101-005056b07da3",
        quencherId: "e8e92175-51cf-e511-8e5c-005056bc0200",
      };
    });
  }

  createTraitIds() {
    return this.selectedOptionTraits.map((trait: selectedOptionTraits) => {
      return { traitId: trait.id, traitVersion: trait.version }
    });
  }

  createNewAnalysisDefinitionEntryParameters(traits: any, labIds: string[]) {
    const adeStatus = this.adeForm.get('adeStatus')?.value;
    const { isActive, isValid, prodVal } = this.mapADEStatus(adeStatus);

    const assayGroup = this.adeForm.get('assayGroup')?.value || [];
    const backbone = this.adeForm.get('backbone')?.value ? (this.adeForm.get('backbone')?.value == 'Yes' ? true : false) : false;

    return {
      analysisDefinitionId: '00000000-0000-0000-0000-000000000000',
      analysisDefinitionVersion: 0,

      assayTypeId: this.getMappedId('AssayType', ASSAY_TYPES_ID_NAME_MAP),
      cropId: this.getMappedId('Crop', CROP_TYPES_ID_NAME_MAP),
      tissuePreparationId: this.getMappedId('TissuePreparation', TISSUE_PREPARATIONS_ID_NAME_MAP),
      extractionMethodId: this.getMappedId('ExtractionType', EXTRACTION_METHODS_ID_NAME_MAP),
      tissueTypeId: this.getMappedId('TissueType', TISSUE_TYPES_ID_NAME_MAP),
      controlTemplateId: this.getMappedId('ControlTemplate', CONTROL_TEMPLATES_ID_NAME_MAP),

      cyclingMethodId: this.getFormValue('CylinderMethod')?.id,
      scoringMethodId: this.getFormValue('ScoringMethod')?.id,
      masterMixId: this.getFormValue('MasterMix')?.id,
      instrumentCategoryId: this.getFormValue('InstrumentCategory')?.id,
      tissueSamplingId: this.getMappedId('TissueSampling', TISSUE_SAMPLINGS_ID_NAME_MAP),

      apAssayEnabled: this.isAssayGroupEnabled(assayGroup, 'AP'),
      eventAssayEnabled: this.isAssayGroupEnabled(assayGroup, 'Event'),
      standardAssayEnabled: this.isAssayGroupEnabled(assayGroup, 'Standard'),

      isActive: isActive,
      isValid: isValid,
      prodVal: prodVal,
      labIds: labIds,
      pooledSamples: this.getFormValue('PooledSamples'),
      backbone: backbone,
      traits: traits,

      baselines: [
        {
          AnalysisModeId: this.getMappedId('Baseline', ANALYSIS_MODES_ID_NAME_MAP),
          Start: this.adeForm.get('BaselineStart')?.value ? Number(this.adeForm.get('BaselineStart')?.value) : 0,
          End: this.adeForm.get('BaselineEnd')?.value ? Number(this.adeForm.get('BaselineEnd')?.value) : 0
        }
      ],
      thresholds: [
        {
          AnalysisModeId: this.getMappedId('Threshold', ANALYSIS_MODES_ID_NAME_MAP),
          Value: this.adeForm.get('ThresholdValue')?.value ? Number(this.adeForm.get('ThresholdValue')?.value) : 0
        }
      ]
    };
  }

  createRequestModel(traitDetails: saveTraitsModel[], newAnalysisDefinitionEntryParameters: AnalysisDefinitionEntryParameter[]) {

    return {
      assayOligoBoxNumber: this.summaryForm.get('assayOligo')?.value,
      traitIds: traitDetails,
      createdById: '00000000-0000-0000-0000-000000000000',
      statusId: this.statusOptionsID,
      analysisDefinitionEntry: ["00000000-0000-0000-0000-000000000000"],
      newAnalysisDefinitionEntryParameters: newAnalysisDefinitionEntryParameters,
    };
  }

  private handleSuccess(data: { id: string }) {
    this.messageService.add({ severity: 'success', summary: 'Success', detail: `Data saved successfully with id : ${data.id}` });
    setTimeout(() => {
      this.router.navigate(['/analysis-definition']);
    }, 1000);
  }

  private handleError(error: any) {
    this.messageService.add({ severity: 'error', summary: 'Error', detail: `Data not saved: ${error.error.message}`, life: 3000 });
    this.isLoading = false;
  }

  saveForm() {
    if (this.adeForm.valid) {
      const labIds = [this.labIds];

      this.traits = this.createTraits();

      if (this.endogenousTraitID) {
        this.traits.push({
          traitId: this.endogenousTraitID,
          traitVersion: this.endogenousTraitVersion,
          forwardOligoConcentration: this.adeForm.get('endogeniousForward')?.value,
          reverseOligoConcentration: this.adeForm.get('endogeniousReverse')?.value,
          probeOligoConcentration: this.adeForm.get('endogeniousProbe')?.value,

          //TODO: These values should be fetched from the API in Traits Response along with the traitId's
          dyeId: "cd1a58c2-3aa3-e711-8101-005056b07da3",
          quencherId: "e8e92175-51cf-e511-8e5c-005056bc0200",
        });
      }
      const traitDetails = this.createTraitIds();
      const newAnalysisDefinitionEntryParameters = [this.createNewAnalysisDefinitionEntryParameters(this.traits, labIds)];
      const requestModel = this.createRequestModel(traitDetails, newAnalysisDefinitionEntryParameters);

      this.isLoading = true;

      this.analysisDefinitionEntriesService
        .SaveAnalysisDefinitionEntry(requestModel)
        .subscribe(
          (data) => {
            this.handleSuccess(data);
          },
          (error) => {
            this.handleError(error);
          }
        );
    }
    else {
      this.messageService.add({ severity: 'warn', summary: 'Missing Required Fields', detail: 'Please fill all the fields', life: 2000 });
    }
  }
}
