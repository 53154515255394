import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import {
  MsalGuardConfiguration,
  MsalInterceptorConfiguration,
  MsalModule,
} from '@azure/msal-angular';
import { PublicClientApplication } from '@azure/msal-browser';
import { ResearchComponentsCoreModule } from '@corteva-research/ngx-components-core';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { TableModule } from 'primeng/table';
import { AnalysisDefinitionCreationComponent } from './analysis-definition-creation/analysis-definition-creation.component';
import { AnalysisDefinitionComponent } from './analysis-definition/analysis-definition.component';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { config } from './config';
import { DashboardOptionsComponent } from './dashboard-options/dashboard-options.component';
import { AuthInterceptor } from './interceptors/auth.interceptor';
import { LabsComponent } from './pages/labs/labs.component';
// PrimeNG Modules
import { FormsModule } from '@angular/forms';
import {
  BrowserAnimationsModule,
  NoopAnimationsModule,
} from '@angular/platform-browser/animations';
import { AccordionModule } from 'primeng/accordion';
import { MessageService } from 'primeng/api';
import { ButtonModule } from 'primeng/button';
import { CalendarModule } from 'primeng/calendar';
import { ContextMenuModule } from 'primeng/contextmenu';
import { DropdownModule } from 'primeng/dropdown';
import { InputTextModule } from 'primeng/inputtext';
import { MenuModule } from 'primeng/menu';
import { MultiSelectModule } from 'primeng/multiselect';
import { PaginatorModule } from 'primeng/paginator';
import { ToastModule } from 'primeng/toast';
import { TooltipModule } from 'primeng/tooltip';
import { AnalysisDefinitionDetailsComponent } from './analysis-definition-details/analysis-definition-details.component';
import { EndogenousCropAssaysComponent } from './endogenous-crop-assays/endogenous-crop-assays.component';
import { ErrorDialogComponent } from './error-dialog/error-dialog.component';
import { ExistingADErrorDialogComponent } from './existing-ad-error-dialog/existing-ad-error-dialog.component';
import { LoginComponentComponent } from './login-component/login-component.component';
import { AuthService } from './services/auth/auth.service';

const msalModule = MsalModule.forRoot(
  new PublicClientApplication({
    auth: {
      clientId: config.clientId,
      authority: config.authority,
      redirectUri: config.redirectUri,
    },
    cache: {
      cacheLocation: 'sessionStorage',
    },
  }),
  <MsalGuardConfiguration>(<unknown>null),
  <MsalInterceptorConfiguration>(<unknown>null),
);

@NgModule({
  declarations: [
    AppComponent,
    LabsComponent,
    DashboardOptionsComponent,
    AnalysisDefinitionComponent,
    AnalysisDefinitionCreationComponent,
    ErrorDialogComponent,
    AnalysisDefinitionDetailsComponent,
    EndogenousCropAssaysComponent,
    ExistingADErrorDialogComponent,
    LoginComponentComponent,
  ],
  imports: [
    AppRoutingModule,
    BrowserModule,
    HttpClientModule,
    msalModule,
    ResearchComponentsCoreModule.forRoot(''),
    TableModule,
    ReactiveFormsModule,
    CalendarModule,
    MultiSelectModule,
    BrowserAnimationsModule,
    NoopAnimationsModule,
    InputTextModule,
    DropdownModule,
    ButtonModule,
    AutoCompleteModule,
    TooltipModule,
    MenuModule,
    FormsModule,
    PaginatorModule,
    AccordionModule,
    ContextMenuModule,
    ToastModule
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    AuthService,
    MessageService
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
